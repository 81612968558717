import {
    DeviceFormFactor,
    useDeviceForm,
} from '@soluto-private/mx-asurion-ui-react-v3';

export enum ScreenSize {
    Small = 'small',
    Large = 'large',
}

export function useScreenSize() {
    const deviceForm = useDeviceForm();
    return deviceForm === DeviceFormFactor.Mobile ||
        deviceForm === DeviceFormFactor.Tablet
        ? ScreenSize.Small
        : ScreenSize.Large;
}

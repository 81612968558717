import {
    AccountItemGroupConfig,
    MyAccountItem,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { ContentfulNavLink, useNavLinks } from '../contentful';
import { TFunction, useTranslation } from 'react-i18next';

import { AccountDropdownConfig } from '../components/header';
import { Partner } from '@soluto-private/mx-types';
import { logout } from '@soluto-private/mx-app-authentication';
import { navigateToUrl } from 'single-spa';
import { useMemo } from 'react';

const ADOTCOM_URL = new URL('https://www.asurion.com');
const PHONE_CLAIM_URL = new URL('https://www.phoneclaim.com');

const PartnerLogoutUrls: Partial<Record<Partner, string>> = {
    [Partner.Asurion]: new URL(ADOTCOM_URL).toString(),
    [Partner.ATT]: new URL('att/protectadvantage', ADOTCOM_URL).toString(),
    [Partner.Cox]: new URL(ADOTCOM_URL).toString(),
    [Partner.Cricket]: new URL('cricket', PHONE_CLAIM_URL).toString(),
    [Partner.DirecTV]: new URL(ADOTCOM_URL).toString(),
    [Partner.Koodo]: new URL('koodo', PHONE_CLAIM_URL).toString(),
    [Partner.Liberty]: new URL(ADOTCOM_URL).toString(),
    [Partner.Telus]: new URL('telus', PHONE_CLAIM_URL).toString(),
    [Partner.USCellular]: new URL('uscellular', ADOTCOM_URL).toString(),
    [Partner.Verizon]: new URL(ADOTCOM_URL).toString(),
};

export const useAccountItemGroups = (
    partner: Partner,
    leftNavEnabled: boolean,
    accountDropdownConfig?: AccountDropdownConfig,
    filteredPartners?: Partner[]
): AccountItemGroupConfig[] => {
    const { t } = useTranslation();
    const contentfulNavLinks = useNavLinks(partner);

    const accountDropdownLinks = useMemo(() => {
        const contentfulNavLinksByType = Object.fromEntries<ContentfulNavLink>(
            contentfulNavLinks.map((navLink) => [navLink.type, navLink])
        );

        const navLinks =
            accountDropdownConfig?.navLinks
                ?.filter(({ type }) => !!contentfulNavLinksByType[type])
                ?.map((navLink) => ({
                    ...contentfulNavLinksByType[navLink.type],
                    ...navLink,
                })) ?? contentfulNavLinks;
        return navLinks;
    }, [contentfulNavLinks, accountDropdownConfig?.navLinks]);

    const itemGroups: AccountItemGroupConfig[] = [];

    if (!!accountDropdownConfig || leftNavEnabled) {
        itemGroups.push(getNavLinksItemGroup(t, accountDropdownLinks));
    }

    itemGroups.push(getDefaultItemGroup(t, partner, filteredPartners));

    return itemGroups;
};

const getNavLinksItemGroup = (
    t: TFunction,
    navLinks: ContentfulNavLink[]
): AccountItemGroupConfig => {
    const navLinksItemGroup = navLinks.map<MyAccountItem>((link) => ({
        name: t(link.label),
        id: link.type,
        onClick: () => {
            navigateToUrl(link.url);
        },
    }));

    return {
        id: 'MyAccount_NavLinksGroup',
        items: navLinksItemGroup,
    };
};

const getDefaultItemGroup = (
    t: TFunction,
    partner: Partner,
    filteredPartners?: Partner[]
): AccountItemGroupConfig => {
    const isPartnersRoute = window.location.pathname.startsWith('/partners');

    return {
        id: 'MyAccount_DefaultGroup',
        items: [
            ...switchPartnerLink(t, isPartnersRoute, filteredPartners),
            backToAsurionComLink(t),
            signOutLink(t, partner),
        ],
    };
};

const switchPartnerLink = (
    t: TFunction,
    isPartnersRoute: boolean,
    filteredPartners?: Partner[]
) => {
    const hasFilter = filteredPartners && filteredPartners.length === 1;
    return !isPartnersRoute
        ? [
              {
                  name: hasFilter
                      ? t('Add provider')
                      : t('Switch or add provider'),
                  id: 'SwitchPartnerLink',
                  onClick: () => {
                      navigateToUrl(
                          hasFilter
                              ? '/partners/switch'
                              : '/partners/switch?filter=true'
                      );
                  },
              },
          ]
        : [];
};

const backToAsurionComLink = (t: TFunction) => {
    return {
        name: t('Back to Asurion.com'),
        id: 'ADotComLink',
        onClick: () => {
            navigateToUrl(ADOTCOM_URL.toString());
        },
    };
};

const signOutLink = (t: TFunction, partner: Partner) => {
    return {
        name: t('Sign out'),
        id: 'SignOutLink',
        onClick: async () => {
            const redirectUrl =
                PartnerLogoutUrls[partner] || ADOTCOM_URL.toString();

            const params = { origin: 'userSignedOut' };
            const paramString = new URLSearchParams(params);

            clearSessionStorage();

            await logout({
                redirectUri: `${redirectUrl}?${paramString.toString()}`,
            });
        },
    };
};

const clearSessionStorage = () => {
    const mxSessionStorageKeys = Object.keys(window.sessionStorage).filter(
        (key) => key.startsWith('mx')
    );
    mxSessionStorageKeys.forEach((key) =>
        window.sessionStorage.removeItem(key)
    );
};

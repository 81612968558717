import React, { FunctionComponent } from 'react';

const CogDoubleIcon: FunctionComponent = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.70801 12.5013C10.0386 12.8664 10.5082 13.0746 11.0007 13.0746C11.4932 13.0746 11.9627 12.8664 12.2933 12.5013L13.1293 11.568C13.6232 11.0218 14.4073 10.8454 15.0876 11.1273C15.7678 11.4092 16.1972 12.0886 16.16 12.824L16.0973 14.0693C16.0696 14.5621 16.2533 15.0434 16.6023 15.3924C16.9513 15.7414 17.4325 15.9251 17.9253 15.8973L19.1707 15.8347C19.9066 15.7964 20.5868 16.2259 20.8687 16.9068C21.1505 17.5876 20.9729 18.3722 20.4253 18.8653L19.5 19.7013C19.1342 20.0315 18.9254 20.5012 18.9254 20.994C18.9254 21.4868 19.1342 21.9565 19.5 22.2867L20.4253 23.1227C20.9729 23.6158 21.1505 24.4004 20.8687 25.0812C20.5868 25.7621 19.9066 26.1916 19.1707 26.1533L17.9253 26.0907C17.4331 26.0651 16.9531 26.2494 16.6046 26.5979C16.2561 26.9465 16.0718 27.4264 16.0973 27.9187L16.16 29.164C16.1972 29.8994 15.7678 30.5788 15.0876 30.8607C14.4073 31.1426 13.6232 30.9662 13.1293 30.42L12.2933 29.4867C11.9627 29.1216 11.4932 28.9134 11.0007 28.9134C10.5082 28.9134 10.0386 29.1216 9.70801 29.4867L8.87201 30.42C8.37811 30.9662 7.59404 31.1426 6.91377 30.8607C6.2335 30.5788 5.80411 29.8994 5.84134 29.164L5.90401 27.9187C5.92875 27.4267 5.74412 26.9472 5.39579 26.5989C5.04747 26.2505 4.56799 26.0659 4.07601 26.0907L2.83068 26.1533C2.09524 26.1906 1.41589 25.7612 1.13397 25.0809C0.852044 24.4006 1.0285 23.6166 1.57468 23.1227L2.50134 22.2867C2.86639 21.956 3.07464 21.4865 3.07464 20.994C3.07464 20.5015 2.86639 20.0319 2.50134 19.7013L1.57468 18.8653C1.0285 18.3714 0.852044 17.5874 1.13397 16.9071C1.41589 16.2268 2.09524 15.7974 2.83068 15.8347L4.07601 15.8973C4.56822 15.9229 5.0482 15.7386 5.39672 15.39C5.74524 15.0415 5.9296 14.5615 5.90401 14.0693L5.84134 12.824C5.80411 12.0886 6.2335 11.4092 6.91377 11.1273C7.59404 10.8454 8.37811 11.0218 8.87201 11.568L9.70801 12.5013Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 23.5013C12.3807 23.5013 13.5 22.382 13.5 21.0013C13.5 19.6206 12.3807 18.5013 11 18.5013C9.61929 18.5013 8.5 19.6206 8.5 21.0013C8.5 22.382 9.61929 23.5013 11 23.5013Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M24.8506 6.66666C24.5745 6.66666 24.3506 6.89051 24.3506 7.16666C24.3506 7.4428 24.5745 7.66666 24.8506 7.66666C25.1268 7.66666 25.3506 7.4428 25.3506 7.16666C25.3506 6.89051 25.1268 6.66666 24.8506 6.66666V6.66666"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.2587 2.464C28.6283 2.32008 29.0481 2.40827 29.3286 2.68876C29.6091 2.96925 29.6973 3.38902 29.5533 3.75866L28.8867 5.47333C28.7284 5.87964 28.852 6.34168 29.192 6.61466L30.6253 7.76666C30.9344 8.01483 31.0677 8.42213 30.9652 8.80502C30.8627 9.18791 30.5437 9.47411 30.152 9.53466L28.3347 9.816C27.9037 9.8819 27.5653 10.2198 27.4987 10.6507L27.2173 12.4693C27.1568 12.861 26.8706 13.18 26.4877 13.2825C26.1048 13.385 25.6975 13.2517 25.4493 12.9427L24.2973 11.5093C24.0242 11.1696 23.5626 11.0455 23.156 11.2027L21.4413 11.8693C21.0717 12.0132 20.6519 11.9251 20.3714 11.6446C20.0909 11.3641 20.0028 10.9443 20.1467 10.5747L20.8133 8.86C20.9717 8.45369 20.848 7.99165 20.508 7.71866L19.0747 6.56533C18.7662 6.31736 18.6333 5.91062 18.7358 5.52834C18.8383 5.14606 19.1569 4.86038 19.548 4.8L21.3653 4.51866C21.7963 4.4518 22.1345 4.11366 22.2013 3.68266L22.4827 1.86666C22.5426 1.47486 22.8286 1.15556 23.2114 1.05299C23.5943 0.950409 24.0016 1.08396 24.2493 1.39333L25.4027 2.82666C25.6759 3.16633 26.1377 3.28986 26.544 3.132L28.2587 2.464Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default CogDoubleIcon;

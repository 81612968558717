import { useEffect, useState } from 'react';

import { CONTAINER_ID } from '../ServicesPopup';
import throttle from 'lodash.throttle';

const useIsContainerScrolledToTop = (): boolean => {
    const [isOnTop, setIsOnTop] = useState(false);

    useEffect(() => {
        const element = document.getElementById(CONTAINER_ID);

        const handleScroll = throttle(() => {
            setIsOnTop((element?.scrollTop ?? 0) < 20);
        }, 200);

        handleScroll();
        element?.addEventListener('scroll', handleScroll);

        return () => {
            element?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isOnTop;
};

export default useIsContainerScrolledToTop;

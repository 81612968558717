import { ContentfulNavLink, NavLinkType } from './types';

import { Partner } from '@soluto-private/mx-types';
import { useContentfulItems } from './useContentfulItems';
import { useMemo } from 'react';

type ContentfulNavLinkInternal = Omit<ContentfulNavLink, 'type'> & {
    id: NavLinkType;
};

export const useNavLinks = (partner: Partner): ContentfulNavLink[] => {
    const query = useMemo(
        () => ({
            type: 'navigationLink',
            tags: [partner],
        }),
        [partner]
    );

    const { result } = useContentfulItems<ContentfulNavLinkInternal>(query);

    const sortedResult = useMemo(
        () =>
            result
                ?.sort((a, b) => a.priority - b.priority)
                ?.map(({ id, ...rest }) => ({ type: id, ...rest })) ?? [],
        result
    );

    return sortedResult;
};

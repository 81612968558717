import {
    Icon,
    Text,
    colors,
    getFontSize,
} from '@soluto-private/mx-asurion-ui-react-v3';
import React, { FC } from 'react';

import { DisplaySize } from '../../DisplaySize';
import { ProfileDetailsProps } from './types';
import { maskUserIdentifier } from '../../utils';
import styled from 'styled-components';

const ProfileDetailsContainer = styled.div`
    height: 58px;
    display: flex;
    align-items: center;
    padding: 8px 12px;

    object {
        width: 42px;
        ${DisplaySize.Small} {
            width: 58px;
        }
    }
`;

const EmailContainer = styled(Text)`
    margin-left: 11px;
    font-size: ${getFontSize(1)};
    color: ${colors.neutralDeepest};

    ${DisplaySize.Small} {
        margin-left: 20px;
        font-size: ${getFontSize(2)};
    }
`;

export const ProfileDetails: FC<ProfileDetailsProps> = ({ userIdentifier }) => (
    <ProfileDetailsContainer>
        <Icon src="RoundedUser" size="large" />
        <EmailContainer>{maskUserIdentifier(userIdentifier)}</EmailContainer>
    </ProfileDetailsContainer>
);

export type ContentfulConfig = {
    spaceId: string;
    environment: string;
    accessToken: string;
};

export type ContentfulContentQuery = {
    type: string;
    tags?: (string | undefined)[];
    include?: number;
};

export type ContentfulCollectionQuery<P extends Record<string, unknown>> =
    ContentfulContentQuery & {
        defaultProps?: P;
    };

export type ContentfulNavLink = {
    type: NavLinkType;
    label: string;
    url: string;
    priority: number;
};

export enum NavLinkType {
    Account = 'MyAccountNavigationLink',
    Claims = 'MyClaimsNavigationLink',
    PlansDevices = 'MyPlansNavigationLink',
    Services = 'AvailableServicesNavigationLink',
    Support = 'SupportNavigationLink',
}

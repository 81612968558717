import { NavCardByType, NavCardGrid } from '../components';
import React, { FC, KeyboardEventHandler, RefCallback } from 'react';

import { GlobalHeaderCardContent } from '../contentful';

export type MenuItemContentProps = {
    cardContent: GlobalHeaderCardContent[];
    columnsDesktop: number;
    columnsMobile: number;
    itemRef: (itemIndex: number) => RefCallback<HTMLElement>;
    onKeyDown: (itemIndex: number) => KeyboardEventHandler<HTMLElement>;
};

export const MenuItemContent: FC<MenuItemContentProps> = ({
    cardContent,
    columnsDesktop,
    columnsMobile,
    itemRef,
    onKeyDown,
}) => (
    <NavCardGrid columns={columnsDesktop} columnsMobile={columnsMobile}>
        {cardContent.map(({ id, type, tag, ...restProps }, index) => {
            const NavCard = NavCardByType[type];
            return (
                <NavCard
                    key={`NavCard_${id}`}
                    onKeyDown={onKeyDown(index)}
                    innerRef={itemRef(index)}
                    tagText={tag}
                    {...restProps}
                />
            );
        })}
    </NavCardGrid>
);

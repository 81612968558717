import { NavMenu, NavMenuProps } from './NavMenu/NavMenu';
import React, { FC } from 'react';

import { DisplaySize } from '../../DisplaySize';
import styled from 'styled-components';

const NavPlaceholder = styled.nav`
    position: relative;
    margin: auto;

    ${DisplaySize.Small} {
        flex-direction: column;
        width: 100%;
        margin: initial;
    }
`;

export const NavArea: FC<NavMenuProps> = (props) => {
    return props.items?.length > 0 ? (
        <NavMenu {...props} />
    ) : (
        <NavPlaceholder />
    );
};

import { CardImageProps, ContainerItemProps } from './types';
import {
    colors,
    getFontSize,
    getFontWeight,
} from '@soluto-private/mx-asurion-ui-react-v3';

import { DisplaySize } from '../../DisplaySize';
import styled from 'styled-components';

export const Title = styled.h4`
    font-size: ${getFontSize(2)};
    font-weight: ${getFontWeight('base')};
    padding: 0;
`;

export const Image = styled.div<CardImageProps>`
    background-image: url(${(props) => props.imgSrc});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    flex-shrink: 0;
    ${DisplaySize.Small} {
        width: 92px;
        height: 92px;
    }
`;

export const Container = styled.button<ContainerItemProps>`
    font-family: Apercu;
    background: none;
    border-radius: 0.5rem;
    position: relative;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
    color: ${colors.black};
    box-shadow: 0px 0px 0px 1px ${colors.neutralBright} inset;
    transition: box-shadow 200ms;
    display: flex;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${colors.black} inset;
        outline: none;
    }
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
`;

export const Description = styled.p`
    color: ${colors.neutralDeeper};
    font-size: ${getFontSize(1)};
    text-align: left;
    margin-top: 0;
    line-height: 1.35;
    margin: 0;
    @media ${DisplaySize.Small} {
        font-size: ${getFontSize(2)};
    }
`;

import {
    Agreement,
    BasePlanDetails,
    Partner,
    ProductType,
} from '@soluto-private/mx-types';
import { PlanSelectionProps, ServiceItemType } from '../types';
import { findAgreement, pickPlan } from '../plan-handler';

import FileEdit from '../icons/FileEdit';
import { UserContextProps } from '../../../providers/UserContextProvider/types';
import { getFileAClaimUrl } from '../file-a-claim-link-generator';
import { initPlanPicker } from './utils';

const title = 'Start a claim';

const attChannelId = '4ECA222A37B63627E0536138060AB356';
const attPrepaidChannelId = '80375A75127456EEE053A239030AE1ED';
const coxChannelId = 'BEECDF7AA98F2E76E0539B39030A8FF2';
const koodoChannelId = '48604F94A51444B6E053A239030ABFB9';
const telusChannelId = '48604F94A51244B6E053A239030ABFB9';
const usCellularChannelId = '8F306CE8693E073EE0536138060AB049';
const dishChannelId = '48A8ED69257666B3E0535E38060A7302';

export const PARTNER_MOBILITY_CLAIMS_URL_SUBPATH: Record<string, string> = {
    [attChannelId]: 'att/en-us',
    [attPrepaidChannelId]: 'att-prepaid/en-us',
    [coxChannelId]: 'cox/en-us',
    [koodoChannelId]: 'koodo/en-ca',
    [telusChannelId]: 'telus/en-ca',
    [usCellularChannelId]: 'uscellular/en-us',
    [dishChannelId]: 'dish/en-us',
};

const FAILURE_REDIRECT = 'https://www.asurion.com/';

const handleMobilityPlanSelection = (channelId = ''): void => {
    const subPath = PARTNER_MOBILITY_CLAIMS_URL_SUBPATH[channelId];

    if (!subPath) {
        console.error(
            `${channelId} client channel id is not handled by file-a-claim menu item`
        );
        window.open(FAILURE_REDIRECT, '_blank');
        return;
    }
    const mobilityClaimsUrl = [
        process.env.MOBILITY_CLAIMS_BASE_URL,
        subPath,
        'workflow/start',
    ].join('/');

    window.open(mobilityClaimsUrl, '_blank');
};

export const routeToClaims = (
    plan: BasePlanDetails,
    partner: Partner,
    plans: BasePlanDetails[],
    agreements: Agreement[]
): void => {
    switch (plan.productType) {
        case ProductType.Home: {
            const url = getFileAClaimUrl(
                'file-a-claim',
                partner,
                plans,
                agreements,
                'home'
            );
            window.open(url, '_blank');
            break;
        }
        case ProductType.Mobility: {
            const agreement = findAgreement(plan, agreements);
            handleMobilityPlanSelection(
                agreement?.ClientAccount.ClientChannelId
            );
            break;
        }
        default:
            console.error(
                `${JSON.stringify(
                    plan
                )} is not handled by file-a-claim menu item`
            );
    }
};

export default {
    category: 'repair',
    displayName: title,
    IconComponent: FileEdit,
    handlePlanSelection: (planProps: PlanSelectionProps) => {
        const contextProps: UserContextProps = planProps.context;
        const planComparator = (
            baselinePlan: BasePlanDetails,
            plan: BasePlanDetails
        ) => {
            if (baselinePlan.productType !== plan.productType) return true;
            const baselineAgreement = findAgreement(
                baselinePlan,
                contextProps.agreements
            );
            const agreement = findAgreement(plan, contextProps.agreements);
            return (
                baselineAgreement?.ClientAccount.ClientChannelId !==
                agreement?.ClientAccount.ClientChannelId
            );
        };
        const routeToClaimsCallback = (plan: BasePlanDetails) =>
            routeToClaims(
                plan,
                contextProps.partner,
                contextProps.plans,
                contextProps.agreements
            );

        void pickPlan(
            contextProps.plans,
            () => initPlanPicker(planProps, title),
            routeToClaimsCallback,
            planComparator
        );
    },
    order: 0,
    actionId: 'FileAClaim',
} as ServiceItemType;

import { NavCardProps, NavCardType } from './types';

import { ComponentType } from 'react';
import { NavCardArticle } from './NavCardArticle';
import { NavCardButton } from './NavCardButton';
import { NavCardDefault } from './NavCardDefault';
import { NavCardDevice } from './NavCardDevice';

export const NavCardByType: Record<NavCardType, ComponentType<NavCardProps>> = {
    [NavCardType.Article]: NavCardArticle,
    [NavCardType.Button]: NavCardButton,
    [NavCardType.Default]: NavCardDefault,
    [NavCardType.Device]: NavCardDevice,
};

import dispatcher from '../api/analyticsDispatcher';
import { useCallback } from 'react';

export type ExtraProps = { [key in string]: string };
type EventType = 'click' | 'view';

const eventTypeToDisplayName: { [key in EventType]: string } = {
    click: 'Click',
    view: 'View',
};

const useAnalyticsDispatch = (scope: string, eventType: EventType) => {
    const dispatch = useCallback(
        (extra?: ExtraProps) => {
            const eventName = eventTypeToDisplayName[eventType];
            const event = `OneService_${scope}_${eventName}`;

            dispatcher.dispatch(event, {
                ExtraData: {
                    PageName: 'Header',
                    Url: location.pathname,
                    ...extra,
                },
            });
        },
        [scope, eventType]
    );

    return dispatch;
};

export default useAnalyticsDispatch;

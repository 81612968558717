import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { ServiceItemType } from './types';
import { navigateToUrl } from 'single-spa';
import useUserContext from '../../hooks/useUserContext';

type Props = ServiceItemType;

const baseWrapperStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
`;

const baseLinkStyle = css`
    ${baseWrapperStyle}

    &,
    &:active,
    &:focus,
    &:visited,
    &:hover {
        color: currentColor;
        text-decoration: none;
    }
`;

const StyledLink = styled.a`
    ${baseLinkStyle}
`;

const StyledButton = styled.button`
    ${baseLinkStyle}
    width: 164px;
`;

const ServiceLink: FunctionComponent<
    Pick<Props, 'targetUrl' | 'openInNewTab' | 'displayName'>
> = ({ children, targetUrl, openInNewTab = false, displayName }) => {
    const context = useUserContext();

    const _targetUrl =
        typeof targetUrl === 'function' ? targetUrl(context) : targetUrl;

    if (!_targetUrl) {
        return (
            <StyledButton data-test-id={displayName}>{children}</StyledButton>
        );
    }
    const isExternalUrl =
        openInNewTab ||
        !_targetUrl.startsWith('/') ||
        _targetUrl.startsWith('//');

    if (isExternalUrl) {
        return (
            <StyledLink
                data-test-id={displayName}
                href={_targetUrl}
                target="_blank"
            >
                {children}
            </StyledLink>
        );
    }

    return (
        <StyledLink
            data-test-id={displayName}
            href={_targetUrl}
            onClick={navigateToUrl}
        >
            {children}
        </StyledLink>
    );
};

export default ServiceLink;

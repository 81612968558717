import { PickerOpts, PlanPickerContextProps } from './types';
import React, { FunctionComponent, createContext, useState } from 'react';

export type { PickerConfigProps, TooltipOpts, PickerOpts } from './types';

export const PlanPickerContext = createContext<PlanPickerContextProps>({
    open: false,
    config: {
        options: [],
    },
    setOpen: () => {},
    setConfig: () => {},
});

const PlanPickerProvider: FunctionComponent = ({ children }) => {
    const [open, setOpen] = useState(false);
    const optionArr: PickerOpts[] = [];
    const [config, setConfig] = useState({ options: optionArr });

    return (
        <PlanPickerContext.Provider
            value={{ open, setOpen, config, setConfig }}
        >
            {children}
        </PlanPickerContext.Provider>
    );
};

export default PlanPickerProvider;

import { DisplaySize } from '../DisplaySize';
import { ReactNode } from 'react';
import styled from 'styled-components';

export type NavCardGridProps = {
    columns?: number;
    columnsMobile?: number;
    isSubNav?: boolean;
    children: ReactNode;
};

export const NavCardGrid = styled.div<NavCardGridProps>`
    display: grid;
    grid-template-columns: repeat(${({ columns = 3 }) => columns}, 1fr);
    grid-column-gap: 0.75rem;
    grid-row-gap: ${({ isSubNav }) => (isSubNav ? '0' : '0.75rem')};
    width: 100%;
    ${DisplaySize.Small} {
        grid-template-columns: repeat(
            ${({ columnsMobile = 1 }) => columnsMobile},
            1fr
        );
    }
`;

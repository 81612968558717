import { HeaderState } from '../../components/header';

export const defaultLogoClickHandler = (homePath: string) => () => {
    if (!homePath) {
        const path = `/${window.location.pathname.split('/')[1]}`;
        window.location.href = path;
        return;
    }

    const path = homePath.startsWith('/') ? homePath : `/${homePath}`;
    window.location.href = path;
};

export const defaultHeaderState: HeaderState = {
    view: undefined,
    backTitle: 'Back',
    title: '',
    homePath: '',
    menu: false,
    onBack: () => window.history.back(),
    onLogoClick: undefined,
    subNavConfig: {
        title: '',
        showPartnerLogo: false,
        showBackButton: true,
        showTitleOnNewLine: false,
        backButtonConfig: {
            title: 'Back',
            onBack: () => window.history.back(),
        },
    },
    accountDropdownConfig: undefined,
};

export const getBasePath = (path: string): string => {
    const formatted = path.startsWith('/') ? path : `/${path}`;
    return formatted.split('/')[1];
};

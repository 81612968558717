import { Icon, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import useIsContainerScrolledToTop from './hooks/useIsContainerScrolledToTop';
import useServicesPopup from '../../hooks/useServicesPopup';

const Container = styled.div<{ isOnTop: boolean }>`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: ${colors.white};
    border: 1px solid ${colors.neutralBrighter};
    position: sticky;
    top: 0;
    z-index: 1;

    ${({ isOnTop }) =>
        isOnTop &&
        css`
            border-color: transparent;
        `}
`;

const StyledButton = styled.button`
    padding: 0;
    margin-right: 16px;
    cursor: pointer;
`;

const StickyBar: FunctionComponent = () => {
    const { close } = useServicesPopup();
    const isOnTop = useIsContainerScrolledToTop();

    return (
        <Container isOnTop={isOnTop}>
            <StyledButton data-test-id="StickyBar_CloseButton" onClick={close}>
                <Icon src="CloseX" size="large" />
            </StyledButton>
        </Container>
    );
};

export default StickyBar;

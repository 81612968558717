import React, { FunctionComponent } from 'react';

const GaugeDashboard: FunctionComponent = () => (
    <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17 32.3333C25.4684 32.3333 32.3334 25.4684 32.3334 17C32.3334 8.53162 25.4684 1.66666 17 1.66666C8.53165 1.66666 1.66669 8.53162 1.66669 17C1.66669 25.4684 8.53165 32.3333 17 32.3333Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.8854 18.8853C17.839 19.8959 16.1758 19.8815 15.1472 18.8529C14.1186 17.8242 14.1041 16.161 15.1147 15.1147C16.156 14.0733 25.9574 8.048 25.9574 8.048C25.9574 8.048 19.9334 17.844 18.8854 18.8853Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.66669 17H7.66669"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.98669 8.98666L10.4 10.4"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17 5.66666V7.66666"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M28.3334 17H26.3334"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M28.5107 27.1333C25.1313 24.8012 21.1052 23.5887 17 23.6667C12.8949 23.5887 8.86875 24.8012 5.48938 27.1333"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default GaugeDashboard;

import {
    ServiceCategoryName,
    getExternalOpenMenuMethod,
} from './components/services-popup';

import { HeaderEvent } from './components/header';
import { HeaderState } from './components/header/types';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import { emitter } from './EventEmitter';
import singleSpaReact from 'single-spa-react';

const lifeCycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary() {
        // Customize the root error boundary for your micro-frontend here.
        return <></>;
    },
});

export const { bootstrap, mount, unmount } = lifeCycles;

export * from './components/header/types';
export * from './EventEmitter';
export type { ServiceCategoryName } from './components/services-popup';

export const updateHeader = (state: HeaderState): boolean => {
    return emitter.dispatchEvent(
        new CustomEvent<HeaderState>(HeaderEvent.Updated, {
            detail: { ...state },
        })
    );
};

export const openMenu = (atCategory?: ServiceCategoryName) => {
    getExternalOpenMenuMethod()(atCategory);
};

import { ScreenSize, useScreenSize } from '../../../../../hooks';
import styled, { css } from 'styled-components';

import { ChevronProps } from './types';
import React from 'react';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';

export const Chevron = styled.i<ChevronProps>`
    display: flex;
    transform: scaleY(1);
    transition: 300ms;
    ${({ isSelected }) =>
        isSelected &&
        css`
            transform: scaleY(-1);
            transition: 300ms;
        `}
`;

export const ChevronArrow = ({
    isSelected,
    isMenuActive,
}: {
    isSelected: boolean;
    isMenuActive: boolean;
}) => {
    return (
        <Chevron role="presentation" isSelected={isSelected}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {useScreenSize() === ScreenSize.Small ? (
                    <path
                        d="M6 9L12 15L18 9"
                        stroke="black"
                        strokeWidth={isSelected ? '2.4' : '1.5'}
                        strokeLinecap="square"
                        strokeLinejoin="round"
                    />
                ) : (
                    <path
                        d="M8 10L12 14L16 10"
                        stroke={
                            !isSelected && isMenuActive
                                ? colors.neutralDeeper
                                : colors.black
                        }
                        strokeWidth="1.2"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                    />
                )}
            </svg>
        </Chevron>
    );
};

import { Description, Header, Image, Title } from './commonStyles';
import React, { FC } from 'react';

import { DisplaySize } from '../../DisplaySize';
import { NavCardContainer } from './NavCardContainer';
import { NavCardProps } from './types';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const ImageExtended = styled(Image)`
    min-height: 135px;
    height: 35%;
    width: 100%;
    transition: 200ms 100ms;

    ${DisplaySize.Small} {
        width: 92px;
        min-height: 92px;
        background-size: cover;
    }
`;

const DivContainer = styled.div`
    width: 100%;
    overflow: hidden;
    border-radius: 4px;

    ${DisplaySize.Small} {
        width: 92px;
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 100%;
    }
`;

const ContainerExtended = styled(NavCardContainer)`
    padding-bottom: 1rem;
    flex-direction: column;

    ${DisplaySize.Small} {
        flex-direction: row;
        gap: 0.75rem;
        padding-bottom: 0.5rem;
    }
    &:hover {
        box-shadow: 0px 0px 0px 1px ${colors.black} inset;
        ${Image} {
            transition: 500ms;
            transform: scale(1.1);
        }
    }
`;

const HeaderExtended = styled(Header)`
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem;
    ${DisplaySize.Small} {
        padding: 0;
        width: calc(100% - 108px);
    }
`;

const TitleExtended = styled(Title)`
    margin: 0.5rem 0;
    text-align: left;
`;

export const NavCardDefault: FC<NavCardProps> = ({
    title,
    description,
    imgSrc,
    ...restProps
}) => (
    <ContainerExtended {...restProps}>
        <DivContainer>
            <ImageExtended imgSrc={imgSrc} />
        </DivContainer>
        <HeaderExtended>
            <TitleExtended>{title}</TitleExtended>
            {description && <Description>{description}</Description>}
        </HeaderExtended>
    </ContainerExtended>
);

import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
} from 'react';
import { Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';

import { PickerConfigProps } from '../../providers/PlanPickerProvider';
import { ServiceItemType } from './types';
import ServiceLink from './ServiceLink';
import { categoryNameToAnalyticName } from './mappings';
import styled from 'styled-components';
import useAnalyticsDispatch from '../../hooks/useAnalyticsDispatch';
import usePlanPicker from '../../hooks/usePlanPicker';
import useServicesPopup from '../../hooks/useServicesPopup';
import { useTranslation } from 'react-i18next';
import useUserContext from '../../hooks/useUserContext';

type Props = ServiceItemType;

const Container = styled.li`
    width: 164px;
    border-radius: 8px;
    margin-bottom: 16px;
    user-select: none;
    text-align: center;
    background-color: ${colors.neutralBrightest};
    cursor: pointer;

    &:hover {
        background-color: ${colors.neutralBrighter};
    }

    &:active {
        background-color: ${colors.neutralBright};
    }
`;

const IconContainer = styled.div`
    display: flex;
    margin-bottom: 12px;
`;

const ServiceItem: FunctionComponent<Props> = ({
    IconComponent,
    displayName,
    targetUrl,
    onClick,
    actionId,
    category,
    handlePlanSelection,
    openInNewTab,
}) => {
    const { close } = useServicesPopup();
    const dispatch = useAnalyticsDispatch('Menu', 'click');
    const context = useUserContext();
    const { setOpen, setConfig } = usePlanPicker();
    const { t } = useTranslation();

    const clickHandler = useCallback(() => {
        close();
        onClick?.();

        const configurePicker: Dispatch<SetStateAction<PickerConfigProps>> =
            setConfig;
        const showPicker: Dispatch<SetStateAction<boolean>> = setOpen;
        handlePlanSelection?.({ context, configurePicker, showPicker });

        if (!actionId) {
            return;
        }

        dispatch({
            ActionId: actionId,
            Cta: displayName,
            Section: categoryNameToAnalyticName[category],
        });
    }, [
        close,
        onClick,
        dispatch,
        actionId,
        displayName,
        category,
        context,
        setOpen,
        setConfig,
        handlePlanSelection,
    ]);

    return (
        <Container onClick={clickHandler}>
            <ServiceLink
                targetUrl={targetUrl}
                openInNewTab={openInNewTab}
                displayName={displayName}
            >
                <IconContainer>
                    <IconComponent />
                </IconContainer>
                <Text size={1}>{t(displayName)}</Text>
            </ServiceLink>
        </Container>
    );
};

export default ServiceItem;

import React, { FunctionComponent } from 'react';

const CloudWifi: FunctionComponent = () => (
    <svg
        width="34"
        height="32"
        viewBox="0 0 34 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.3334 23.3333C32.3347 21.5239 31.5991 19.7919 30.296 18.5364C28.993 17.2809 27.2349 16.6102 25.4267 16.6787C23.6126 12.8212 19.412 10.6868 15.2268 11.4959C11.0416 12.3049 7.93911 15.8511 7.69335 20.1067C6.21933 19.7979 4.68466 20.1698 3.51551 21.1191C2.34636 22.0684 1.66722 23.494 1.66669 25C1.66669 30.08 7.00002 30 7.00002 30H26.3334C26.3334 30 32.3334 29.2187 32.3334 23.3333Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.7027 3.90533C18.8971 1.36473 13.9363 1.36473 10.1307 3.90533"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M19.7454 7.33334C17.7301 5.98992 15.1047 5.98992 13.0894 7.33334"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default CloudWifi;

import { Description, Header, Image, Title } from './commonStyles';
import React, { FC } from 'react';
import { Tag, colors } from '@soluto-private/mx-asurion-ui-react-v3';

import { DisplaySize } from '../../DisplaySize';
import { NavCardContainer } from './NavCardContainer';
import { NavCardProps } from './types';
import styled from 'styled-components';

const ImageExtended = styled(Image)`
    width: 70px;
    height: 70px;
`;

const TitleExtended = styled(Title)`
    margin: 0;
    line-height: 1.35;
    text-align: left;
`;

const ContainerExtended = styled(NavCardContainer)`
    gap: 0.75rem;
    flex-direction: row;
    box-shadow: rgb(213 214 218) 0px 0px 0px 0px inset;
    &:hover {
        box-shadow: 0px 0px 0px 1px ${colors.neutralDeep} inset;
    }

    ${DisplaySize.Small} {
        box-shadow: 0px 0px 0px 1px ${colors.neutralBright} inset;
    }
`;

const HeaderExtended = styled(Header)`
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
`;

export const NavCardArticle: FC<NavCardProps> = ({
    title,
    description,
    imgSrc,
    tagText,
    ...restProps
}) => (
    <ContainerExtended {...restProps}>
        <ImageExtended imgSrc={imgSrc} />
        <HeaderExtended>
            {tagText && <Tag text={tagText} size="regular" type="light" />}
            <TitleExtended>{title}</TitleExtended>
            {description && <Description>{description}</Description>}
        </HeaderExtended>
    </ContainerExtended>
);

import React from 'react';

export type CardImageProps = {
    imgSrc?: string;
};

export type ContainerItemProps = React.ComponentPropsWithoutRef<'a'> & {
    selected?: boolean;
    index?: number;
    innerRef?: React.RefCallback<HTMLAnchorElement>;
};
export type NavCardProps = React.ComponentPropsWithoutRef<'a'> &
    ContainerItemProps & {
        title: string;
        description?: string;
        imgSrc?: string;
        tagText?: string;
    };
export type ButtonProps = {
    iconSrc: string;
    variant: string;
    color: string;
};

export enum NavCardType {
    Article = 'article',
    Button = 'button',
    Default = 'default',
    Device = 'device',
}

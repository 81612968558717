import { MutableRefObject, useEffect, useRef, useState } from 'react';

const currentScroll = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const { scrollTop } = document.documentElement || document.body;
    return scrollTop;
};

export const useScrollState = (
    containerRef: MutableRefObject<HTMLDivElement | null>,
    topOffset: number
) => {
    const [onTop, setOnTop] = useState(() => currentScroll() === 0);
    const prevScrollRef = useRef<number>(currentScroll());
    const offset = useRef<number>(0);

    useEffect(() => {
        const onScroll = () => {
            const scrollY = currentScroll();
            if (scrollY < topOffset && !onTop) {
                setOnTop(true);
            } else if (scrollY > topOffset && onTop) {
                setOnTop(false);
            }
            if (prevScrollRef.current < 0) {
                offset.current = 0;
            } else if (scrollY > prevScrollRef.current) {
                const delta = scrollY - prevScrollRef.current;
                offset.current = Math.max(offset.current - delta, -topOffset);
            } else if (scrollY < prevScrollRef.current) {
                const delta = prevScrollRef.current - scrollY;
                offset.current = Math.min(offset.current + delta, 0);
            }

            prevScrollRef.current = scrollY;

            requestAnimationFrame(() => {
                if (containerRef.current) {
                    containerRef.current.style.transform = `translateY(${offset.current}px)`;
                }
            });
        };

        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [onTop, topOffset, containerRef]);

    return { onTop };
};

import {
    Button,
    Container,
    ItemsContainer,
    Link,
    SubNavHeader,
    SubnavItem,
} from './styles';
import {
    LinkProps,
    useScrollingUp,
} from '@soluto-private/mx-asurion-ui-react-v3';
import React, { forwardRef, useState } from 'react';

export type SubNavProps = {
    id?: string;
    depthOffset?: number;
    rightLink?: LinkWithLabelProps;
    logo: {
        image: React.ImgHTMLAttributes<HTMLImageElement>;
        link: LinkWithLabelProps;
    };
    items: ItemProps[];
};

export type ItemProps = {
    title?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export type LinkWithLabelProps = LinkProps & {
    label?: string;
};

export const SubNav = forwardRef<HTMLDivElement, SubNavProps>(function SubNav(
    { rightLink, depthOffset = 0, items = [], logo, id },
    fRef
) {
    const [selectedValue, setSelectedValue] = useState<string>();
    const isScrollingUp = useScrollingUp(true);

    return (
        <SubNavHeader
            id={id}
            ref={fRef}
            showHeader={isScrollingUp}
            depthOffset={depthOffset}
        >
            <Container>
                <Link {...logo.link}>
                    <img {...logo.image} alt={logo.image.alt} />
                </Link>
                {items.length && (
                    <ItemsContainer>
                        {items.map(({ title, onClick }, index) => (
                            <SubnavItem key={index}>
                                <Button
                                    isSelected={title === selectedValue}
                                    onClick={(e) => {
                                        setSelectedValue(title);
                                        onClick?.(e);
                                    }}
                                >
                                    {title}
                                </Button>
                            </SubnavItem>
                        ))}
                    </ItemsContainer>
                )}
                <Link {...rightLink}>{rightLink?.label}</Link>
            </Container>
        </SubNavHeader>
    );
});

import HouseTool from '../icons/HouseTool';
import { ServiceItemType } from '../types';

export default {
    category: 'solutions',
    displayName: 'In-store HomeTech services',
    IconComponent: HouseTool,
    targetUrl: '/dashboard/store-tech-services',
    order: 0,
    actionId: 'GoToStoreServices',
} as ServiceItemType;

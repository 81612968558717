import * as S from './HeaderStyles';

import {
    GlobalHeaderInnerProps,
    GlobalHeaderState,
    StyledClaimsButton,
} from './types';
import React, { FC, useRef } from 'react';

import { AccountButton } from './components/AccountButton/AccountButton';
import { AccountMenu } from './components/AccountMenu';
import { Key } from './utils';
import { Logo } from './components/Logo';
import { MenuButton } from './HeaderStyles';
import { NavArea } from './components/NavArea';
import { useClickOutside } from '@soluto-private/mx-asurion-ui-react-v3';
import { useScreenSize } from './hooks';

export const GlobalHeaderDesktop: FC<
    GlobalHeaderInnerProps & GlobalHeaderState
> = ({
    menuItems = [],
    accountItemGroups,
    logoProps = {},
    claimsButtonProps,
    myAccountButtonProps,
    waffleButtonProps,
    isLoggedIn = false,
    userIdentifier,
    isNavMenuActive,
    isMyAccountMenuActive,
    setNavMenuActive,
    setMyAccountMenuActive,
    closeMenus,
}) => {
    const onKeyDownEventHandler: React.KeyboardEventHandler<
        HTMLButtonElement
    > = (e) => {
        if (e.key === Key.Escape) {
            closeMenus();
        }
    };

    const showWaffleButton =
        waffleButtonProps && !claimsButtonProps && isLoggedIn;
    const screenSize = useScreenSize();
    const dropdownRef = useRef<HTMLDivElement>(null);
    useClickOutside(closeMenus, dropdownRef);
    return (
        <>
            {logoProps?.imageProps && <Logo {...logoProps} />}
            <S.NavContainer isMenuActive={isNavMenuActive}>
                <NavArea
                    items={menuItems}
                    isNavMenuActive={isNavMenuActive}
                    setNavMenuActive={setNavMenuActive}
                />
                <S.Actions isMyAccountMenuActive={isMyAccountMenuActive}>
                    {showWaffleButton && (
                        <MenuButton
                            aria-label="WaffleButton"
                            iconSrc="ServicesMenu"
                            variant="flat"
                            color="secondary"
                            size="medium"
                            {...waffleButtonProps}
                        />
                    )}
                    {myAccountButtonProps && (
                        <div
                            ref={dropdownRef}
                            aria-label="Open My Account menu"
                        >
                            <AccountButton
                                showClaimsButton={!!claimsButtonProps}
                                isLoggedIn={isLoggedIn}
                                isSelected={isMyAccountMenuActive}
                                screenSize={screenSize}
                                onClick={() => {
                                    setMyAccountMenuActive(
                                        !isMyAccountMenuActive
                                    );
                                }}
                                onKeyDown={onKeyDownEventHandler}
                                {...myAccountButtonProps}
                            />
                            <AccountMenu
                                isActive={isMyAccountMenuActive}
                                itemGroups={accountItemGroups}
                                userIdentifier={userIdentifier}
                                showClaimsButton={!!claimsButtonProps}
                                closeMenu={closeMenus}
                            />
                        </div>
                    )}
                    {claimsButtonProps && (
                        <StyledClaimsButton
                            {...claimsButtonProps}
                            variant="button"
                        >
                            Claims
                        </StyledClaimsButton>
                    )}
                </S.Actions>
            </S.NavContainer>
        </>
    );
};

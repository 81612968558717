import React, {
    FC,
    ReactNode,
    createContext,
    useContext,
    useMemo,
} from 'react';

import { ContentfulConfig } from './types';

type ContentfulContext = {
    config: ContentfulConfig;
};
const ContentfulContext = createContext<ContentfulContext | undefined>(
    undefined
);

export const useContentfulContext = (): ContentfulContext => {
    const context = useContext(ContentfulContext);

    if (context === undefined) {
        throw new Error('Contentful context is undefined');
    }

    return context;
};

type ContentfulProviderProps = {
    config?: ContentfulConfig;
    children?: ReactNode;
};

export const ContentfulProvider: FC<ContentfulProviderProps> = ({
    children,
    config,
}) => {
    const context = useMemo(
        () => (config === undefined ? undefined : { config }),
        [config?.spaceId, config?.environment, config?.accessToken]
    );

    return (
        <ContentfulContext.Provider value={context}>
            {children}
        </ContentfulContext.Provider>
    );
};

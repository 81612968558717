import { NavCardType } from '../components/NavCard/types';
import { useContentfulItems } from './useContentfulItems';
import { useMemo } from 'react';

export type GlobalHeaderCardContent = {
    id: string;
    type: NavCardType;
    title: string;
    description?: string;
    imgSrc?: string;
    tag?: string;
    href?: string;
    priority: number;
};

export const useGlobalHeaderCardContent = (
    menu: string
): GlobalHeaderCardContent[] => {
    const query = useMemo(
        () => ({
            type: 'globalHeaderCardContent',
            tags: [menu],
            defaultProps: {
                id: '',
                type: NavCardType.Default,
                title: '',
                description: '',
                tag: '',
                imgSrc: '',
                href: '',
                priority: -1,
            },
        }),
        [menu]
    );

    const { result } = useContentfulItems<GlobalHeaderCardContent>(query);

    return result?.sort((a, b) => a.priority - b.priority) ?? [];
};

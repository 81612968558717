import React, { FunctionComponent } from 'react';

import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.header`
    margin: 16px 0 24px;
`;

const Heading: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Text size={5} weight="heavy">
                {t('Services')}
            </Text>
        </Container>
    );
};
export default Heading;

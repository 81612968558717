import React, { FunctionComponent } from 'react';

const RouterSignal: FunctionComponent = () => (
    <svg
        width="34"
        height="32"
        viewBox="0 0 34 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.748 6.99066C20.5288 7.77166 20.5288 9.03766 19.748 9.81866"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.576 4.16135C23.7013 5.28657 24.3334 6.8127 24.3334 8.40401C24.3334 9.99532 23.7013 11.5215 22.576 12.6467"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.576 4.16135C23.7013 5.28657 24.3334 6.8127 24.3334 8.40401C24.3334 9.99532 23.7013 11.5215 22.576 12.6467"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M25.4 1.33334C27.2756 3.20873 28.3292 5.75238 28.3292 8.40468C28.3292 11.057 27.2756 13.6006 25.4 15.476"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M25.4 1.33334C27.2756 3.20873 28.3292 5.75238 28.3292 8.40468C28.3292 11.057 27.2756 13.6006 25.4 15.476"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.252 6.99066C13.4713 7.77166 13.4713 9.03766 14.252 9.81866"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.4241 4.16135C10.2988 5.28657 9.66669 6.8127 9.66669 8.40401C9.66669 9.99532 10.2988 11.5215 11.4241 12.6467"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.4241 4.16135C10.2988 5.28657 9.66669 6.8127 9.66669 8.40401C9.66669 9.99532 10.2988 11.5215 11.4241 12.6467"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.60004 1.33334C6.72451 3.20873 5.67084 5.75238 5.67084 8.40468C5.67084 11.057 6.72451 13.6006 8.60004 15.476"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.60004 1.33334C6.72451 3.20873 5.67084 5.75238 5.67084 8.40468C5.67084 11.057 6.72451 13.6006 8.60004 15.476"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.3334 25.3333C32.3334 26.8061 31.1394 28 29.6667 28H4.33335C2.86059 28 1.66669 26.8061 1.66669 25.3333V22.6667C1.66669 21.1939 2.86059 20 4.33335 20H29.6667C31.1394 20 32.3334 21.1939 32.3334 22.6667V25.3333Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7.33333 23.6667C7.14924 23.6667 7 23.8159 7 24C7 24.1841 7.14924 24.3334 7.33333 24.3334C7.51743 24.3334 7.66667 24.1841 7.66667 24C7.66667 23.8159 7.51743 23.6667 7.33333 23.6667V23.6667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12 23.6667C11.8159 23.6667 11.6667 23.8159 11.6667 24C11.6667 24.1841 11.8159 24.3334 12 24.3334C12.1841 24.3334 12.3334 24.1841 12.3334 24C12.3334 23.8159 12.1841 23.6667 12 23.6667V23.6667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17 20V13.3333"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.33337 28L4.33337 30.6667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M27.6667 28L29.6667 30.6667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default RouterSignal;

import styled from 'styled-components';

export const SlidingContainer = styled.div<{ showTop?: boolean }>`
    width: 100%;
    max-width: 1440px;
    position: fixed;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
    background: white;
`;

export const Shadow = styled.div<{ show: boolean }>`
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    box-shadow: 0px -1px 0px 0px #e6e6eb inset;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.2s;
`;

export const GlobalHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Head = styled.div<{ height: string }>`
    display: flex;
    align-items: center;
    height: ${(props) => props.height}px;
    width: 100%;
`;

export const Placeholder = styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
    width: 100%;
`;

export const Title = styled.span<{ show: boolean }>`
    text-align: center;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.2s;
`;

export const CenteredTitle = styled(Title)`
    margin-left: 0px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
`;

export const Icon = styled.div<{ $isClickable: boolean }>`
    display: flex;
    width: auto;
    margin-right: 16px;
    ${({ $isClickable }) => $isClickable && `cursor: pointer`}
`;

export const IconRight = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;

    > *:not(:last-child) {
        margin-right: 14px;
    }
`;

export const Button = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: flex-end;
    z-index: 1;
`;

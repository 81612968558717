import { useEffect, useState } from 'react';

import { getPrincipalIdentifier } from '../services';

export const useUserIdentifier = () => {
    const [identifier, setIdentifier] = useState('');

    useEffect(() => {
        const retrieveIdentifier = async () => {
            setIdentifier(await getPrincipalIdentifier());
        };

        void retrieveIdentifier();
    }, [identifier]);

    return identifier;
};

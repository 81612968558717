import RouterSignal from '../icons/RouterSignal';
import { ServiceItemType } from '../types';

export default {
    category: 'optimize',
    displayName: 'WiFi network help',
    IconComponent: RouterSignal,
    targetUrl: '/wifi-support',
    order: 4,
    actionId: 'GoToWifiCheck',
} as ServiceItemType;

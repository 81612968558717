import {
    MenuItemName,
    ServiceCategoryItemType,
    ServiceCategoryName,
    ServiceItemType,
} from './types';
import {
    deviceCheckupItem,
    deviceSetupItem,
    digitalSecureItem,
    expertSupportItem,
    fileAClaimItem,
    homeTechItem,
    inHomeItem,
    myClaimsItem,
    photosItem,
    securityAdvisorItem,
    streamingAdvisorItem,
    wifiSupportItem,
} from './services';
import {
    optimizeCategory,
    repairCategory,
    solutionsCategory,
    supportCategory,
} from './categories';

import remoteBackup from './services/remote-backup';

export const categoryTypeToCategoryMap: {
    [key in ServiceCategoryName]: ServiceCategoryItemType;
} = {
    repair: repairCategory,
    support: supportCategory,
    optimize: optimizeCategory,
    solutions: solutionsCategory,
};

export const menuItemTypeToServiceItem: {
    [key in MenuItemName]: ServiceItemType;
} = {
    'file-a-claim': fileAClaimItem,
    'my-claims': myClaimsItem,
    'device-setup': deviceSetupItem,
    'expert-support': expertSupportItem,
    'security-advisor': securityAdvisorItem,
    'digital-secure': digitalSecureItem,
    'streaming-advisor': streamingAdvisorItem,
    'device-checkup': deviceCheckupItem,
    'home-tech': homeTechItem,
    'in-home': inHomeItem,
    photos: photosItem,
    'wifi-support': wifiSupportItem,
    'remote-backup': remoteBackup,
};

export const serviceToMenuItemsMap: Record<string, MenuItemName[] | undefined> =
    {
        claims: ['file-a-claim', 'my-claims'],
        'expert-support': ['expert-support'],
        'device-care': ['device-checkup'],
        'device-setup': ['device-setup'],
        'digital-secure': ['digital-secure'],
        photos: ['photos'],
        'streaming-advisor': ['streaming-advisor'],
        'digital-security': ['security-advisor'],
        UBIF: ['home-tech'],
        'UBIF-replacement': ['remote-backup'],
        'in-home': ['in-home'],
        'wifi-support': ['wifi-support'],
    };

export const categoryNameToAnalyticName: {
    [key in ServiceCategoryName]: string;
} = {
    optimize: 'ImprovePerformance',
    solutions: 'ExpertSolutions',
    repair: 'RepairOrReplace',
    support: 'SetupAndSupport',
};

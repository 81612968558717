import BackButton from './BackButton';
import { CenteredTitle } from './styles';
import React from 'react';
import { SubNavState } from './types';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { usePartnerProductConfiguration } from '../../hooks/usePartnerProductConfiguration';

type SubNavProps = {
    onTop: boolean;
} & SubNavState;

const SubNavBody = styled.div<{
    showBackButton?: boolean;
    showPartnerLogo?: boolean;
}>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    width: 100%;
    z-index: 0;
    ${({ showBackButton }) => showBackButton && `padding-bottom: 8px`};
    ${({ showPartnerLogo, showBackButton }) =>
        !showPartnerLogo && showBackButton && `padding-top: 8px`};
`;

const SubNavTitle = styled(CenteredTitle)<{
    showPartnerLogo?: boolean;
    showBackButton?: boolean;
    showTitleOnNewLine?: boolean;
}>`
    ${({ showTitleOnNewLine }) =>
        showTitleOnNewLine &&
        `
        flex-basis: 100%;
        position: relative;
        width: auto;
        padding-bottom: 8px;
        `};
    ${({ showPartnerLogo, showBackButton }) =>
        showPartnerLogo && showBackButton && `bottom: 8px`};
`;

const SubNavContainer = styled.div`
    margin-left: 16px;
`;

const SubNav: React.FC<SubNavProps> = ({
    onTop,
    title,
    showPartnerLogo,
    showBackButton,
    backButtonConfig,
    showTitleOnNewLine,
}) => {
    const { header } = usePartnerProductConfiguration();

    showPartnerLogo = showPartnerLogo && !!header.subNav?.logo;
    return showBackButton || showPartnerLogo ? (
        <SubNavBody
            showBackButton={showBackButton}
            showPartnerLogo={showPartnerLogo}
        >
            <SubNavContainer>
                {showPartnerLogo && header.subNav && (
                    <div data-test-id="MxHeader_PartnerLogo">
                        <img
                            height={'48px'}
                            src={header.subNav.logo.url}
                            alt={header.subNav.logo.alt}
                        />
                    </div>
                )}
                {showBackButton && (
                    <BackButton
                        onTop={onTop}
                        title={backButtonConfig?.title}
                        onBack={backButtonConfig?.onBack}
                    />
                )}
            </SubNavContainer>
            <SubNavTitle
                id="mx-heading"
                show={!onTop}
                showPartnerLogo={showPartnerLogo}
                showBackButton={showBackButton}
                showTitleOnNewLine={showTitleOnNewLine}
            >
                <Text size={2}>{title}</Text>
            </SubNavTitle>
        </SubNavBody>
    ) : null;
};

export default SubNav;

import {
    colors,
    getFontSize,
    getFontWeight,
} from '@soluto-private/mx-asurion-ui-react-v3';

import { DisplaySize } from '../../DisplaySize';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 1.5rem;
    gap: 2rem;
    ${DisplaySize.Small} {
        padding: 0;
    }
`;

export const Content = styled.div`
    flex-grow: 1;
`;

export const Description = styled.p`
    font-size: ${getFontSize(2)};
    font-weight: ${getFontWeight('base')};
    color: ${colors.neutralDeeper};
    line-height: 1.35;
`;

export const Header = styled.div`
    width: 275px;
    flex-shrink: 0;
    ${DisplaySize.Small} {
        display: none;
    }
`;

export const Icon = styled.i`
    img {
        width: 60px;
    }
`;

export const Title = styled.h3`
    font-size: ${getFontSize(4)};
    font-weight: ${getFontWeight('feather')};
    line-height: 1.35;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
`;

import {
    Agreement,
    BasePlanDetails,
    Partner,
    ProductType,
} from '@soluto-private/mx-types';
import { PickerOpts, TooltipOpts } from '../../../providers/PlanPickerProvider';
import { onTrial, sortByEnrollmentDate } from '../plan-handler';

import { PlanSelectionProps } from '../types';
import { UserContextProps } from '../../../providers/UserContextProvider/types';
import { getFileAClaimUrl } from '../file-a-claim-link-generator';
import { i18n } from '../../../initializations';
import { navigateToUrl } from 'single-spa';

const routeToClaims = (
    plan: BasePlanDetails,
    partner: Partner,
    plans: BasePlanDetails[],
    agreements: Agreement[]
): void => {
    switch (plan.productType) {
        case ProductType.Home: {
            const url = getFileAClaimUrl(
                'my-claims',
                partner,
                plans,
                agreements,
                'home'
            );
            window.open(url, '_blank');
            break;
        }
        case ProductType.Mobility:
            navigateToUrl('/claims');
            break;
        default:
            console.error(
                `${JSON.stringify(plan)} is not handled by my-claims menu item`
            );
    }
};

const initPlanPicker = (planProps: PlanSelectionProps, title: string) => {
    const contextProps: UserContextProps = planProps.context;
    const allOptions: PickerOpts[] = contextProps.plans
        .sort((p1, p2) => sortByEnrollmentDate(p1, p2, contextProps.agreements))
        .map((p) => {
            const option: PickerOpts = {
                label: p.displayName,
                value: p,
                disabled: () => onTrial(p, contextProps.agreements),
            };
            const tooltip: TooltipOpts = {
                show: true,
                text: 'Your protection plan has a 30-days\nwaiting period for filing a claim.\nYour devices’ coverage will kick-in\nonce that period ends.',
                offset: 0,
            };

            return onTrial(p, contextProps.agreements)
                ? { ...option, tooltip }
                : option;
        });

    planProps.configurePicker({
        title: i18n.get(title),
        subtitle: `${i18n.get('Start by selecting your plan')}:`,
        options: allOptions,
        button: {
            onClickCallback: (o) => {
                routeToClaims(
                    o.value as BasePlanDetails,
                    contextProps.partner,
                    contextProps.plans,
                    contextProps.agreements
                );
            },
            children: i18n.get('Continue'),
        },
    });
    planProps.showPicker(true);
};

export { initPlanPicker, routeToClaims };

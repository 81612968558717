import React, { FunctionComponent } from 'react';

import groupedExpertsIcon from '../assets/grouped_experts.png';
import styled from 'styled-components';

const IconContainer = styled.div`
    img {
        width: 60px;
    }
`;

const GroupedExpertsIcon: FunctionComponent = () => (
    <IconContainer>
        <img src={groupedExpertsIcon as string} alt="Grouped experts icon" />
    </IconContainer>
);

export default GroupedExpertsIcon;

/* eslint-disable */

export type OpenSdk = {
    aeSdk: any;
    text: string;
};

export const openSdk = {
    v2: async ({ aeSdk, text }: OpenSdk): Promise<void> =>
        await aeSdk?.triggerMessagingOverlay(text),
    v3: async ({ aeSdk, text }: OpenSdk): Promise<void> => {
        await aeSdk((error: any, sdk: any) => {
            if (error) {
                return;
            }
            sdk?.triggerMessagingOverlay(text);
        });
    },
};

export type OverrideExpertise = {
    aeSdk: any;
    expertise: string[];
};

export const overrideExpertise = {
    v2: async ({ aeSdk, expertise }: OverrideExpertise): Promise<void> =>
        await aeSdk.messagingApi.overrideExpertise(expertise),
    v3: async ({ aeSdk, expertise }: OverrideExpertise): Promise<void> =>
        new Promise((resolve, reject) => {
            aeSdk(async (thrownError: any, AE_SDK: any) => {
                try {
                    const response = await AE_SDK.appendSettings(
                        (settings: any) => {
                            settings.expertise = expertise;
                            return settings;
                        }
                    );
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            });
        }),
};

export type AeSdkCallback = {
    v2: (unknown: any) => Promise<void>;
    v3: (unknown: any) => Promise<void>;
};

export const aeSdk = async (cb: AeSdkCallback, args?: any) => {
    const aeSdk = (window as any).AE_SDK;
    if (!aeSdk) {
        return;
    }
    if (typeof aeSdk === 'function') {
        await cb.v3({ aeSdk, ...args });
        return;
    }
    await cb.v2({ aeSdk, ...args });
};

import { NavCardGrid, NavCardText } from '../components';
import React, { FC } from 'react';

import { useGlobalHeaderCardContent } from '../contentful';

export const SubNavContent: FC = () => {
    const subNavCards = useGlobalHeaderCardContent('subNavMenu');
    return (
        <NavCardGrid isSubNav={true}>
            {subNavCards.map(({ title, href }, index) => (
                <NavCardText title={title} href={href} key={index} />
            ))}
        </NavCardGrid>
    );
};

import { HeaderEvent, HeaderState } from '../../components/header';
import React, {
    FunctionComponent,
    createContext,
    useEffect,
    useState,
} from 'react';
import {
    defaultHeaderState,
    defaultLogoClickHandler,
    getBasePath,
} from './utils';

import { emitter } from '../../EventEmitter';

type RoutingEvent = {
    oldUrl: string;
    newUrl: string;
};

export const HeaderStateContext = createContext<HeaderState>({} as HeaderState);

const HeaderStateProvider: FunctionComponent = ({ children }) => {
    const [headerState, setHeaderState] =
        useState<HeaderState>(defaultHeaderState);

    useEffect(() => {
        const updateState = ({ detail }: CustomEvent<HeaderState>) => {
            const { onLogoClick, title, onBack, backTitle, subNavConfig } =
                detail;

            setHeaderState({
                ...detail,
                onLogoClick:
                    onLogoClick ??
                    defaultLogoClickHandler(detail.homePath || ''),
                subNavConfig: {
                    title:
                        subNavConfig?.title ??
                        title ??
                        defaultHeaderState.subNavConfig?.title,
                    showPartnerLogo:
                        subNavConfig?.showPartnerLogo ??
                        defaultHeaderState.subNavConfig?.showPartnerLogo,
                    showBackButton:
                        subNavConfig?.showBackButton ??
                        defaultHeaderState.subNavConfig?.showBackButton,
                    showTitleOnNewLine:
                        subNavConfig?.showTitleOnNewLine ??
                        defaultHeaderState.subNavConfig?.showTitleOnNewLine,
                    backButtonConfig: {
                        title:
                            subNavConfig?.backButtonConfig?.title ??
                            backTitle ??
                            defaultHeaderState.subNavConfig?.backButtonConfig
                                ?.title,
                        onBack:
                            subNavConfig?.backButtonConfig?.onBack ??
                            onBack ??
                            defaultHeaderState.subNavConfig?.backButtonConfig
                                ?.onBack,
                    },
                },
            });
        };

        emitter.addEventListener(
            HeaderEvent.Updated,
            updateState as EventListener
        );
        return () => {
            emitter.removeEventListener(
                HeaderEvent.Updated,
                updateState as EventListener
            );
        };
    }, []);

    useEffect(() => {
        const reset = ({ detail }: CustomEvent<RoutingEvent>): void => {
            const oldUrl = new URL(detail.oldUrl);
            const newUrl = new URL(detail.newUrl);
            const newApp = getBasePath(newUrl.pathname);
            const oldApp = getBasePath(oldUrl.pathname);
            if (oldApp !== newApp) {
                setHeaderState((state) => ({
                    ...state,
                    onLogoClick: defaultLogoClickHandler(''),
                    onBack: defaultHeaderState.subNavConfig?.backButtonConfig
                        ?.onBack,
                }));
            }
        };

        window.addEventListener(
            'single-spa:before-routing-event',
            reset as EventListener
        );

        return () => {
            window.removeEventListener(
                'single-spa:before-routing-event',
                reset as EventListener
            );
        };
    }, []);

    return (
        <HeaderStateContext.Provider value={headerState}>
            {children}
        </HeaderStateContext.Provider>
    );
};

export default HeaderStateProvider;

import {
    PartnerProductConfiguration,
    defaultPartnerProductConfiguration,
} from '@soluto-private/mx-types';

import { PartnerProductConfigurationContext } from '../providers/PartnerProductConfigurationProvider';
import { useContext } from 'react';

export const usePartnerProductConfiguration =
    (): PartnerProductConfiguration => {
        return {
            ...defaultPartnerProductConfiguration,
            ...useContext(PartnerProductConfigurationContext),
        };
    };

import React, { FunctionComponent } from 'react';

const CloudImage: FunctionComponent = () => (
    <svg
        width="32"
        height="30"
        viewBox="0 0 32 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M27.3333 17.5413C29.4265 16.573 30.7386 14.4478 30.6667 12.1427C30.6414 10.4367 29.9233 8.81446 28.6774 7.64882C27.4316 6.48319 25.7652 5.87449 24.0613 5.96268C22.5419 2.90523 19.4141 0.97975 16 1.00001C11.3478 0.937548 7.44966 4.50459 7.09999 9.14401C5.71097 8.83862 4.25821 9.17196 3.14127 10.0523C2.02433 10.9327 1.36085 12.2674 1.33332 13.6893C1.22177 15.7681 2.63307 17.6214 4.66666 18.0667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.6667 29H9.33333C8.59695 29 8 28.403 8 27.6667V14.3333C8 13.597 8.59695 13 9.33333 13H22.6667C23.403 13 24 13.597 24 14.3333V27.6667C24 28.403 23.403 29 22.6667 29Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.5 20.3333C14.6046 20.3333 15.5 19.4379 15.5 18.3333C15.5 17.2288 14.6046 16.3333 13.5 16.3333C12.3954 16.3333 11.5 17.2288 11.5 18.3333C11.5 19.4379 12.3954 20.3333 13.5 20.3333Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M24 26L20.812 21.2174C20.6288 20.9422 20.3215 20.7754 19.9909 20.7716C19.6604 20.7679 19.3494 20.9278 19.16 21.1987L16.5 25L14.852 23.6814C14.6329 23.5065 14.3505 23.4315 14.0735 23.4746C13.7966 23.5177 13.5503 23.6749 13.3947 23.908L10 29"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default CloudImage;

import React, { FC } from 'react';

import { DisplaySize } from '../../DisplaySize';
import { LogoClickableWrapper } from './LogoClickableWrapper';
import { LogoImage } from './LogoImage';
import { LogoProps } from './types';
import styled from 'styled-components';

const LogoContainer = styled.div`
    padding-block: 0.75rem;
    display: block !important;
    height: 72px;
    box-sizing: border-box;

    ${DisplaySize.Small} {
        padding-block: 0;
        height: 40px;
        padding: 0 0.5rem;
    }
`;

export const Logo: FC<LogoProps> = (props) => {
    return (
        <LogoContainer>
            <LogoClickableWrapper {...props.clickProps}>
                <LogoImage {...props.imageProps} />
            </LogoClickableWrapper>
        </LogoContainer>
    );
};

import { Agreement, BasePlanDetails } from '@soluto-private/mx-types';

const trialPeriod = 30;

export const findAgreement = (
    plan: BasePlanDetails,
    agreements: Agreement[]
): Agreement | undefined => {
    const agreement = agreements.find((agreement) => agreement.Id === plan.id);

    if (!agreement) {
        console.error(
            `could not find matching agreement for a plan: ${plan.id}`,
            { plan }
        );
        return;
    }

    return agreement;
};

export const onTrial = (
    plan: BasePlanDetails,
    agreements: Agreement[]
): boolean => {
    const agreement = findAgreement(plan, agreements);

    const trialStart = new Date();
    trialStart.setDate(trialStart.getDate() - trialPeriod);

    const purchaseDate = agreement?.Purchase?.PurchaseDate;

    return purchaseDate ? new Date(purchaseDate) > trialStart : false;
};

export const sortByEnrollmentDate = (
    p1: BasePlanDetails,
    p2: BasePlanDetails,
    agreements: Agreement[]
): number => {
    const a1 = findAgreement(p1, agreements);
    const a2 = findAgreement(p2, agreements);

    const a1purchaseDate = a1?.Purchase?.PurchaseDate;
    const a2purchaseDate = a2?.Purchase?.PurchaseDate;

    const d1 = a1purchaseDate ? new Date(a1purchaseDate) : 0;
    const d2 = a2purchaseDate ? new Date(a2purchaseDate) : 0;

    if (d1 < d2) return -1;
    else if (d1 > d2) return 1;
    else return 0;
};

/**
 * @param plans - user plans
 * @param showPicker - callback to present manual plan selection experience
 * @param pick - callback to automatically select plan,
 *    handles single plan and multiple identical plans scenarios
 * @param planComparator  - callback to deside if given plan is uniq
 */
export const pickPlan = async (
    plans: BasePlanDetails[],
    showPicker: () => void,
    pick: (plan: BasePlanDetails) => Promise<void> | void,
    planComparator: (
        baselinePlan: BasePlanDetails,
        plan: BasePlanDetails
    ) => boolean = (baselinePlan, plan) =>
        baselinePlan.productType !== plan.productType
) => {
    const baselinePlan: BasePlanDetails = plans[0];
    const uniqPlans = plans.filter((plan) =>
        planComparator(baselinePlan, plan)
    );

    if (uniqPlans.length) {
        showPicker();
    } else {
        await pick(baselinePlan);
    }
};

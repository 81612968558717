import React, { FunctionComponent } from 'react';
import { ServiceCategoryItemType, ServiceItemType } from './types';

import ServiceList from './ServiceList';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const SERVICE_CATEGORY_ID_PREFIX = 'header-service-category-item-';

const Container = styled.section<{ order: number }>`
    order: ${({ order }) => order};
    margin-bottom: 14px;
`;

const Heading = styled.header`
    display: flex;
    margin-bottom: 14px;
    align-items: center;
`;

const IconContainer = styled.div`
    display: flex;
    margin-right: 5px;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
`;

type Props = {
    services: ServiceItemType[];
} & ServiceCategoryItemType;

const ServiceCategoryItem: FunctionComponent<Props> = ({
    IconComponent,
    displayName,
    services,
    type,
    order,
}) => {
    const { t } = useTranslation();

    return (
        <Container id={`${SERVICE_CATEGORY_ID_PREFIX}${type}`} order={order}>
            <Heading>
                <IconContainer>
                    <IconComponent />
                </IconContainer>
                <Text size={3} weight="heavy">
                    {t(displayName)}
                </Text>
            </Heading>
            <div>
                <ServiceList services={services} />
            </div>
        </Container>
    );
};

export default ServiceCategoryItem;

import { CONTAINER_ID } from './ServicesPopup';
import { SERVICE_CATEGORY_ID_PREFIX } from './ServiceCategoryItem';
import { ServiceCategoryName } from './types';

export const getServicePopupElement = () =>
    document.getElementById(CONTAINER_ID);

export const getCategoryElementId = (category: ServiceCategoryName) =>
    `${SERVICE_CATEGORY_ID_PREFIX}${category}`;

export const scrollToCategory = (category: ServiceCategoryName) => {
    const popupElement = getServicePopupElement();

    const categoryElement = document.getElementById(
        getCategoryElementId(category)
    );

    if (!categoryElement) {
        return;
    }

    popupElement?.scrollTo(0, categoryElement.offsetTop);
};

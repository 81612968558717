import React, { FC } from 'react';
import { ScreenSize, useArrowControls, useScreenSize } from '../hooks';
import {
    useGlobalHeaderCardContent,
    useGlobalHeaderMenuContent,
} from '../contentful';

import { MegaMenu } from '../components';
import { MenuContentBaseProps } from '../types';
import { MenuItemContent } from './MenuItemContent';
import { SubNavContent } from './SubNavContent';

type ContentfulMenuContentProps = MenuContentBaseProps & {
    menuName: string;
};

export const ContentfulMenuContent: FC<ContentfulMenuContentProps> = ({
    menuName,
    closeMegaMenu,
    focusParent,
    focusItem,
    itemRef,
}) => {
    const { columnsDesktop, columnsMobile, ...menuContent } =
        useGlobalHeaderMenuContent(menuName);
    const cardContent = useGlobalHeaderCardContent(menuName);

    const screenSize = useScreenSize();
    const { onKeyDown } = useArrowControls<HTMLAnchorElement>({
        dimensions: {
            columns:
                screenSize === ScreenSize.Small
                    ? columnsMobile
                    : columnsDesktop,
            items: cardContent.length,
        },
        focusParent,
        focusItem,
    });

    return (
        <MegaMenu closeMegaMenu={closeMegaMenu} {...menuContent}>
            {menuName !== 'subNavMenu' && (
                <MenuItemContent
                    cardContent={cardContent}
                    columnsDesktop={columnsDesktop}
                    columnsMobile={columnsMobile}
                    itemRef={itemRef}
                    onKeyDown={onKeyDown}
                />
            )}
            {menuName === 'subNavMenu' && <SubNavContent />}
        </MegaMenu>
    );
};

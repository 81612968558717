import {
    Link as CoreLink,
    Size,
    colors,
    getFontSize,
    getFontWeight,
} from '@soluto-private/mx-asurion-ui-react-v3';

import { DisplaySize } from '../../DisplaySize';
import styled from 'styled-components';

type HeaderProps = {
    showHeader: boolean;
    depthOffset: number;
};

export const SubNavHeader = styled.div<HeaderProps>`
    position: sticky;
    top: ${({ showHeader }) => (showHeader ? '72px' : '-85px')};
    width: 100%;
    background: ${colors.white};
    border-bottom: solid 1px ${colors.neutralBrighter};
    display: flex;
    align-items: center;
    z-index: ${({ depthOffset }) => 10 + depthOffset};
    transition: top 0.2s ease-in-out;
    height: 4.5rem;

    ${DisplaySize.Small} {
        display: none;
    }
`;

export const Container = styled.div`
    margin: auto;
    max-width: ${Size.XL};
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: ${colors.neutralDeepest};
    background: ${colors.white};
    position: relative;
    flex-direction: row;
    padding: 0 1rem;
    align-items: center;
`;

export const ItemsContainer = styled.ul`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-right: 17.5rem;

    ${DisplaySize.Large} {
        margin-right: 6.25rem;
    }
`;

export const SubnavItem = styled.li`
    display: flex;
    align-items: center;
    flex: none;
`;

export const Button = styled.button<{ isSelected: boolean }>`
    background: ${({ isSelected }) =>
        isSelected ? colors.neutralBrightest : colors.white};
    font-size: ${getFontSize(1)};
    color: ${colors.black};
    height: 2.5rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    padding: 0.25rem 0.5rem;
    margin-right: 0.75rem;
    cursor: pointer;
    gap: 2px;
    box-sizing: border-box;
    font-weight: ${getFontWeight('feather')};

    &:hover:not(:disabled) {
        border-color: ${({ isSelected }) =>
            isSelected ? 'transparent' : colors.neutralBright};
        border-radius: 0.5rem;
    }
`;

export const Link = styled(CoreLink)`
    font-weight: ${getFontWeight('feather')};
`;

import * as S from './HeaderStyles';

import {
    ClaimsButtonProps,
    GlobalHeaderInnerProps,
    GlobalHeaderState,
    StyledClaimsButton,
} from './types';
import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';

import { AccountButton } from './components/AccountButton/AccountButton';
import { AccountDropdownContainerProps } from './components/AccountButton/types';
import { AccountMenu } from './components/AccountMenu';
import { Logo } from './components/Logo';
import { MenuButton } from './HeaderStyles';
import { NavArea } from './components/NavArea';
import { useScreenSize } from './hooks';

const AccountDropdownContainer = styled.div<AccountDropdownContainerProps>`
    width: 50%;
    height: 48px;
    ${({ isLoggedIn }) =>
        isLoggedIn &&
        css`
            display: none;
        `}
    ${({ showClaimsButton }) =>
        !showClaimsButton &&
        css`
            width: 100%;
            align-self: stretch;
        `}
`;

const StyledClaimsButtonMobile = styled(StyledClaimsButton)<ClaimsButtonProps>`
    height: 48px;
    ${({ isMyAccountMenuActive }) =>
        isMyAccountMenuActive &&
        css`
            width: 50%;
        `}
`;

export const GlobalHeaderMobile: FC<
    GlobalHeaderInnerProps & GlobalHeaderState
> = ({
    menuItems = [],
    accountItemGroups,
    logoProps = {},
    claimsButtonProps,
    myAccountButtonProps,
    waffleButtonProps,
    isLoggedIn = false,
    userIdentifier,
    isNavMenuActive,
    isMyAccountMenuActive,
    setNavMenuActive,
    setMyAccountMenuActive,
    closeMenus,
}) => {
    const isMenuActive = isNavMenuActive || isMyAccountMenuActive;
    // show nav menu button OR waffle button OR neither
    const showNavMenuButton =
        menuItems.length > 0 && !waffleButtonProps && !isNavMenuActive;
    const showWaffleButton =
        waffleButtonProps &&
        !claimsButtonProps &&
        isLoggedIn &&
        !isNavMenuActive;
    // show my account button OR close button OR neither
    const showMyAccountButton = myAccountButtonProps && !isMenuActive;
    const showCloseButton = isMenuActive;
    const screenSize = useScreenSize();
    const dropdownRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <S.Branding>
                {showNavMenuButton && (
                    <S.MenuButton
                        aria-label="Open menu"
                        iconSrc="HamburgerMenu"
                        variant="flat"
                        color="secondary"
                        size="medium"
                        onClick={() => setNavMenuActive(true)}
                    />
                )}
                {showWaffleButton && (
                    <MenuButton
                        aria-label="WaffleButton"
                        iconSrc="ServicesMenu"
                        variant="flat"
                        color="secondary"
                        size="medium"
                        {...waffleButtonProps}
                    />
                )}
                {logoProps?.imageProps && <Logo {...logoProps} />}
                {showMyAccountButton && (
                    <S.MenuButton
                        aria-label="Open My Account menu"
                        iconSrc="RoundedUser"
                        variant="flat"
                        color="secondary"
                        size="medium"
                        onClick={() => setMyAccountMenuActive(true)}
                        {...myAccountButtonProps}
                    />
                )}
                {showCloseButton && (
                    <S.MenuButton
                        aria-label="Close menu"
                        iconSrc="CloseX"
                        variant="flat"
                        color="secondary"
                        size="medium"
                        onClick={closeMenus}
                    />
                )}
            </S.Branding>
            <S.NavContainer isMenuActive={isMenuActive}>
                <NavArea
                    items={menuItems}
                    isNavMenuActive={isNavMenuActive}
                    setNavMenuActive={() => undefined}
                />
                {isMyAccountMenuActive && (
                    <AccountMenu
                        itemGroups={accountItemGroups}
                        userIdentifier={userIdentifier}
                        showClaimsButton={!!claimsButtonProps}
                        isActive={true}
                        closeMenu={closeMenus}
                    />
                )}
                <S.Actions isMyAccountMenuActive={isMyAccountMenuActive}>
                    {myAccountButtonProps && (
                        <AccountDropdownContainer
                            ref={dropdownRef}
                            showClaimsButton={!!claimsButtonProps}
                            isLoggedIn={isLoggedIn}
                            aria-label="Open My Account menu"
                        >
                            <AccountButton
                                showClaimsButton={!!claimsButtonProps}
                                isLoggedIn={isLoggedIn}
                                isSelected={isMyAccountMenuActive}
                                screenSize={screenSize}
                                onClick={() => {
                                    setMyAccountMenuActive(
                                        !isMyAccountMenuActive
                                    );
                                }}
                                {...myAccountButtonProps}
                            />
                        </AccountDropdownContainer>
                    )}
                    {claimsButtonProps && (
                        <StyledClaimsButtonMobile
                            {...claimsButtonProps}
                            variant="button"
                        >
                            Claims
                        </StyledClaimsButtonMobile>
                    )}
                </S.Actions>
            </S.NavContainer>
        </>
    );
};

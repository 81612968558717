import {
    ContentfulBackend,
    MxContextLanguageDetector,
    createI18n,
} from '@soluto-private/mx-i18n';

import { initReactI18next } from 'react-i18next';

const i18n = createI18n();
void i18n.init({
    plugins: [MxContextLanguageDetector, ContentfulBackend, initReactI18next],
    init: {
        ns: ['global'],
        defaultNS: 'global',
        react: {
            useSuspense: false,
        },
    },
});

export default i18n;

import React, { FC } from 'react';

import { AccountItemGroup } from './AccountItemGroup';
import { AccountItemsProps } from './types';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const StyledList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const AccountItems: FC<AccountItemsProps> = ({
    onClick,
    itemGroups: groups,
}) => (
    <StyledList>
        {groups?.map((g, i) => (
            <AccountItemGroup
                key={`AccountItemGroup-${g.id}`}
                textColor={
                    i === groups.length - 1
                        ? colors.neutralDeeper
                        : colors.black
                }
                id={g.id}
                items={g.items}
                onClick={onClick}
            />
        ))}
    </StyledList>
);

import { AccountButtonProps, StyledButtonProps } from './types';
import {
    Button,
    Icon,
    Text,
    colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { DisplaySize } from '../../DisplaySize';
import { ScreenSize } from '../../hooks';

const StyledButton = styled(Button)<StyledButtonProps>`
    color: ${colors.black};
    border-color: ${colors.black};
    width: 163px;
    height: 40px;
    padding: 0;

    ${DisplaySize.Small} {
        height: 48px;
    }

    ${({ showClaimsButton }) =>
        !showClaimsButton &&
        css`
            ${DisplaySize.Small} {
                width: 100%;
                align-self: stretch;
            }
        `}

    ${({ isLoggedIn }) =>
        isLoggedIn &&
        css`
            width: 179px;
            border: none;
            justify-content: center;
        `}

    ${({ isLoggedIn, showClaimsButton }) =>
        !isLoggedIn &&
        showClaimsButton &&
        css`
            ${DisplaySize.Small} {
                width: 100%;
            }
        `}

  ${({ isSelected, isLoggedIn }) =>
        isLoggedIn &&
        isSelected &&
        css`
            background-color: ${colors.neutralBrighter};
        `}

  &&&:active {
        color: ${colors.black};
        border-color: ${colors.black};
    }
`;

const ButtonContent = styled.div`
    padding: 0 12px;
    display: flex;
    align-items: center;

    object {
        pointer-events: none;
    }
`;

const StyledText = styled(Text)`
    padding: 0 8px;
    display: inline-block;
    margin-top: 2px;
    ${DisplaySize.Small} {
        margin-top: 1px;
    }
`;

const RotatingIcon = styled(Icon)<{ rotateIcon: boolean }>`
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    transform: ${({ rotateIcon }) => `rotate(${rotateIcon ? -180 : 0}deg)`};
`;

export const AccountButton: FC<AccountButtonProps> = ({
    isLoggedIn = false,
    isSelected = false,
    screenSize,
    ...rest
}) => (
    <StyledButton
        isLoggedIn={isLoggedIn}
        isSelected={isSelected}
        aria-label="My Account Button"
        aria-expanded={isLoggedIn ? isSelected : undefined}
        variant="outline"
        data-test-id="my-account-button"
        {...rest}
    >
        <ButtonContent>
            <Icon src="RoundedUser" size="large" />
            <StyledText>My Account</StyledText>
            {isLoggedIn && screenSize === ScreenSize.Large && (
                <RotatingIcon
                    src="ChevronDown"
                    size="large"
                    fill="currentColor"
                    rotateIcon={isSelected}
                />
            )}
        </ButtonContent>
    </StyledButton>
);

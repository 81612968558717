import CloudWifi from '../icons/CloudWifi';
import { ServiceItemType } from '../types';

export default {
    category: 'optimize',
    displayName: 'Streaming support',
    IconComponent: CloudWifi,
    targetUrl: '/wifi-support',
    order: 3,
    actionId: 'GoToWifiCheck',
} as ServiceItemType;

import React, {
    FC,
    ReactNode,
    createContext,
    useContext,
    useMemo,
} from 'react';

import { ContentfulConfig } from './types';

const defaultConfig: ContentfulConfig = {
    spaceId: process.env.SPACE_ID ?? 'norgcvq7fq7t',
    environment: process.env.ENVIRONMENT ?? 'master',
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN ?? '',
};

const ContentfulContext = createContext<{ config: ContentfulConfig }>({
    config: defaultConfig,
});

export const useContentfulContext = () => useContext(ContentfulContext);

type ContentfulProviderProps = Partial<ContentfulConfig> & {
    children?: ReactNode;
};

export const ContentfulProvider: FC<ContentfulProviderProps> = ({
    children,
    ...config
}) => {
    const context = useMemo(
        () => ({
            config: {
                ...defaultConfig,
                ...config,
            },
        }),
        [config.spaceId, config.environment, config.accessToken]
    );

    return (
        <ContentfulContext.Provider value={context}>
            {children}
        </ContentfulContext.Provider>
    );
};

import { MenuItemName, ServiceCategoryName } from '../types';
import React, {
    FunctionComponent,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { getServicePopupElement, scrollToCategory } from '../utils';

import useMenuItems from '../hooks/useMenuItems';

type ServicesPopupContextProps = {
    isActive: boolean;
    open: VoidFunction;
    close: VoidFunction;
    menuItems: MenuItemName[];
};

export const ServicesPopupContext = createContext<ServicesPopupContextProps>({
    isActive: false,
    open: () => void {},
    close: () => void {},
    menuItems: [],
});

let externalOpenMenu: (atCategory?: ServiceCategoryName) => void;

export const getExternalOpenMenuMethod = () => externalOpenMenu;

const ServicesPopupProvider: FunctionComponent = ({ children }) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const menuItems = useMenuItems();

    const open = useCallback((atCategory?: ServiceCategoryName) => {
        setIsActive(true);

        if (!atCategory) {
            getServicePopupElement()?.scrollTo(0, 0);
            return;
        }

        scrollToCategory(atCategory);
    }, []);

    const close = useCallback(() => {
        setIsActive(false);
    }, []);

    const providerValue = useMemo<ServicesPopupContextProps>(
        () => ({
            isActive,
            open,
            close,
            menuItems,
        }),
        [isActive, open, close, menuItems]
    );

    useEffect(() => {
        externalOpenMenu = open;
    }, [open]);

    return (
        <ServicesPopupContext.Provider value={providerValue}>
            {children}
        </ServicesPopupContext.Provider>
    );
};

export default ServicesPopupProvider;

import CogDoubleIcon from '../icons/CogDoubleIcon';
import { Key } from '@soluto-private/mx-context';
import { ServiceItemType } from '../types';

export default {
    category: 'support',
    displayName: 'Mobile device setup',
    IconComponent: CogDoubleIcon,
    targetUrl: (context) => {
        const queryParams = new URLSearchParams({
            [Key.Client]: context.partner,
            [Key.Language]: context.language,
        });
        return `/mobile-device-setup?${queryParams.toString()}`;
    },
    openInNewTab: true,
    actionId: 'GoToDeviceSetup',
    order: 1,
} as ServiceItemType;

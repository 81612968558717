import { Agreement, BasePlanDetails, Partner } from '@soluto-private/mx-types';

import { PlanType } from './types';

type PlanData = {
    type: string;
    partner: Partner;
    subscriptionNumber: string;
    clientchannelid: string;
};

type ClaimType = 'file-a-claim' | 'my-claims';

type Carrier = Partner.Asurion | Partner.ATT | Partner.Verizon;

const HOME_PLAN = 'home';
const VERIZON_RTM = 'vzwph';
const ASURION_RTM = 'homeplus';
const ATT_RTM = 'att';

const ATT_PREPAID_CLIENT_CHANNEL_ID = '80375A75127456EEE053A239030AE1ED';

const rtmToCarrierMapping: { [key in Carrier]: string } = {
    att: ATT_RTM,
    verizon: VERIZON_RTM,
    asurion: ASURION_RTM,
};

function getRtm(carrier: Carrier): string {
    return rtmToCarrierMapping[carrier];
}

const getHomeUrl = (
    claimType: ClaimType,
    subscriptionNumber: string,
    clientChannelId: string,
    carrier: Carrier
) => {
    const rtm = getRtm(carrier);

    const params = [
        ['cid', `vn-rtm:${rtm}`],
        ['SubscriptionNumber', subscriptionNumber],
        ['ClientChannelId', clientChannelId],
        ['CTASource', 'menu'],
        ['tab', claimType === 'my-claims' ? 'CLAIMS' : ''],
    ];

    const urlParams = new URLSearchParams(params.filter((param) => !!param[1]));

    const baseUrl = process.env.CLAIMS_BASE_URL;
    const url = new URL(
        '/connectedhome/en-us/authenticate?' + urlParams.toString(),
        baseUrl
    );

    return url.href;
};

const getMobilityUrl = (
    claimType: ClaimType,
    carrier: string,
    clientchannelid?: string
): string => {
    if (claimType === 'my-claims') return '/claims';

    const baseUrl = process.env.MOBILITY_CLAIMS_BASE_URL;
    const path =
        clientchannelid === ATT_PREPAID_CLIENT_CHANNEL_ID
            ? `att-prepaid/en-us/workflow/start`
            : `${carrier}/en-us/workflow/start`;
    const url = new URL(path, baseUrl);

    return url.href;
};

const getPlanData = (
    partner: Partner,
    plans: BasePlanDetails[],
    agreements: Agreement[],
    planType?: PlanType
): PlanData | undefined => {
    const plan = planType
        ? plans.find((plan) => planType == plan.productType)
        : plans[0];
    if (!plan) {
        return;
    }

    const matchingAgreement = agreements.find(
        (agreement) => agreement.Id === plan.id
    );
    if (!matchingAgreement) {
        return;
    }

    return {
        type: plan.productType,
        partner,
        subscriptionNumber: matchingAgreement.SubscriptionNumber || '',
        clientchannelid: matchingAgreement.ClientAccount.ClientChannelId,
    };
};

export const getFileAClaimUrl = (
    claimType: ClaimType,
    partner?: Partner,
    plans?: BasePlanDetails[],
    agreements?: Agreement[],
    planType?: PlanType
): string => {
    if (!partner || !plans || !agreements) {
        return '';
    }

    const planData = getPlanData(partner, plans, agreements, planType);

    if (!planData) {
        return '';
    }
    return planData.type == HOME_PLAN
        ? getHomeUrl(
              claimType,
              planData.subscriptionNumber,
              planData.clientchannelid,
              planData.partner as Carrier
          )
        : getMobilityUrl(claimType, partner, planData.clientchannelid);
};

import { Key, storage } from '@soluto-private/mx-context';
import { Partner, ProductType } from '@soluto-private/mx-types';

import { UserContextProps } from './types';

export const defaultContext: UserContextProps = {
    partner: Partner.Asurion,
    productType: ProductType.Default,
    services: [],
    plans: [],
    agreements: [],
    language: '',
    filteredPartners: [],
};

export const getContext = (): UserContextProps => {
    const storagePartner = storage.get(Key.Client);
    const partner = Object.values(Partner).includes(storagePartner)
        ? storagePartner
        : defaultContext.partner;

    const storageProductType = storage.get(Key.ProductType);
    const productType = Object.values(ProductType).includes(storageProductType)
        ? storageProductType
        : defaultContext.productType;

    const services = storage.get(Key.Services) || defaultContext.services;
    const plans = storage.get(Key.Plans) ?? defaultContext.plans;
    const language = storage.get(Key.Language) || defaultContext.language;

    const verifiedPlans = storage.get(Key.VerifiedPlans) || [];
    const agreements = verifiedPlans.map((p) => p.agreement);

    const filteredPartners = storage.get(Key.FilteredPartners);

    return {
        partner,
        productType,
        services,
        plans,
        agreements,
        language,
        filteredPartners,
    };
};

import React, { FunctionComponent } from 'react';

import { Header } from './components/header';
import ServicesPopup from './components/services-popup';

const App: FunctionComponent = () => (
    <>
        <Header />
        <ServicesPopup />
    </>
);

export default App;

import KeyLock from '../icons/KeyLock';
import { ServiceItemType } from '../types';

export default {
    category: 'solutions',
    displayName: 'Identity Protection',
    IconComponent: KeyLock,
    targetUrl: '/security',
    order: 2,
    actionId: 'GoToSecurity',
} as ServiceItemType;

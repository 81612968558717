export const maskUserIdentifier = (identifier: string): string => {
    return identifier.includes('@')
        ? maskEmail(identifier)
        : maskPhone(identifier);
};

const maskEmail = (email: string): string => {
    const middle = email.substring(1, email.indexOf('@') - 1);
    return email.replace(middle, '*****');
};

const maskPhone = (phone: string): string =>
    `(***) ***-${phone.substring(phone.length - 4)}`;

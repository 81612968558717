import { ContentfulCollectionQuery } from './types';
import { useContentfulItems } from './useContentfulItems';

export const useContentfulItem = <P extends Record<string, unknown>>(
    query: ContentfulCollectionQuery<P>
) => {
    const { result, ...rest } = useContentfulItems<P>(query);

    return { result: result?.[0] ?? query.defaultProps, ...rest };
};

import * as S from './MegaMenuStyles';

import { Link, LinkProps } from '@soluto-private/mx-asurion-ui-react-v3';
import React, { ReactNode } from 'react';

import { Key } from '../../utils';

export type MegaMenuTypes = {
    title: string;
    description?: string;
    children?: ReactNode;
    iconSrc?: string;
    linkText?: string;
    linkUrl?: string;
    closeMegaMenu?: () => void;
    linkComponent?: React.ReactElement<LinkProps>;
};

export const MegaMenu = ({
    title,
    description,
    iconSrc,
    children,
    linkText,
    linkUrl,
    linkComponent,
    closeMegaMenu,
}: MegaMenuTypes): JSX.Element => {
    if (!linkComponent && linkText && linkUrl) {
        linkComponent = (
            <Link
                iconSrc="ArrowRight"
                iconSide="right"
                color="primary"
                href={linkUrl}
            >
                {linkText}
            </Link>
        );
    }

    return (
        <S.Container
            onKeyDown={(e) => {
                if (e.key === Key.Escape) {
                    closeMegaMenu?.();
                }
            }}
        >
            <S.Header>
                <S.Icon>
                    <img src={iconSrc} alt="" />
                </S.Icon>
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
                {linkComponent}
            </S.Header>
            <S.Content>{children}</S.Content>
        </S.Container>
    );
};

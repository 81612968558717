import './initializations/i18n';

import App from './App';
import HeaderStateProvider from './providers/HeaderStateProvider';
import type { PartnerProductConfiguration } from '@soluto-private/mx-types';
import PartnerProductConfigurationProvider from './providers/PartnerProductConfigurationProvider';
import PlanPickerProvider from './providers/PlanPickerProvider';
import React from 'react';
import ServicesPopupProvider from './components/services-popup/providers/ServicesPopupProvider';
import UserContextProvider from './providers/UserContextProvider';

type RootProps = {
    partnerProductConfiguration: () => Promise<
        Partial<PartnerProductConfiguration>
    >;
};

const Root = ({ partnerProductConfiguration }: RootProps) => (
    <UserContextProvider>
        <HeaderStateProvider>
            <ServicesPopupProvider>
                <PlanPickerProvider>
                    <PartnerProductConfigurationProvider
                        configuration={partnerProductConfiguration}
                    >
                        <App />
                    </PartnerProductConfigurationProvider>
                </PlanPickerProvider>
            </ServicesPopupProvider>
        </HeaderStateProvider>
    </UserContextProvider>
);

export default Root;

import React, { FunctionComponent, useState } from 'react';

import SubNav from './SubNav';
import ViewHeader from './ViewHeader';
import useHeaderState from '../../hooks/useHeaderState';

const ServiceViewHeader: FunctionComponent = () => {
    const [onTop, setOnTop] = useState(true);
    const { subNavConfig } = useHeaderState();

    return (
        <ViewHeader onTopCallback={setOnTop}>
            <SubNav onTop={onTop} {...subNavConfig} />
        </ViewHeader>
    );
};

export default ServiceViewHeader;

import React, { FunctionComponent } from 'react';

const KeyLock: FunctionComponent = () => (
    <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.95995 17.7067C5.17699 17.7266 2.02998 20.6208 1.69416 24.3889C1.35834 28.157 3.94393 31.5622 7.66375 32.2507C11.3836 32.9393 15.0167 30.6853 16.052 27.0467H17.6693L19.0026 28.3347L20.336 27.0533H21.6693L23.0093 28.3253L24.336 27.0533L26.336 27.044L28.3333 24.9533L26.3173 23.04H16.0333C15.1307 19.8824 12.244 17.7058 8.95995 17.7067Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.32666 27.04C7.43123 27.04 8.32666 26.1446 8.32666 25.04C8.32666 23.9355 7.43123 23.04 6.32666 23.04C5.22209 23.04 4.32666 23.9355 4.32666 25.04C4.32666 26.1446 5.22209 27.04 6.32666 27.04Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M31 24.34C31.7364 24.34 32.3333 23.743 32.3333 23.0067V12.3733C32.3297 11.6396 31.7338 11.0467 31 11.0467L16.3333 11.0347C15.597 11.0347 15 11.6316 15 12.368V16.4067"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.6746 11.04L17.6666 7.73333C17.6519 4.41962 20.3263 1.72139 23.64 1.70666C26.9537 1.69194 29.6519 4.36629 29.6666 7.68L29.6813 11.04"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.6667 19.04C24.7713 19.04 25.6667 18.1445 25.6667 17.04C25.6667 15.9354 24.7713 15.04 23.6667 15.04C22.5621 15.04 21.6667 15.9354 21.6667 17.04C21.6667 18.1445 22.5621 19.04 23.6667 19.04Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default KeyLock;

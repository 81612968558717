import Drill from '../icons/Drill';
import { ServiceItemType } from '../types';

export default {
    category: 'solutions',
    displayName: 'In-home services and installs',
    IconComponent: Drill,
    order: 1,
    actionId: 'ScheduleExpertHomeVisit',
} as ServiceItemType;

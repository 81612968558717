import {
    AuthenticationState,
    getAuthenticationState,
} from '@soluto-private/mx-app-authentication';
import {
    GlobalHeader,
    LogoProps,
} from '@soluto-private/oneservice-global-header';
import {
    GlobalHeaderContent,
    Head,
    Placeholder,
    SlidingContainer,
} from './styles';
import React, {
    FunctionComponent,
    memo,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ButtonProps } from '@soluto-private/mx-asurion-ui-react-v3';
import { NavLink } from './types';
import { defaultPartnerProductConfiguration } from '@soluto-private/mx-types';
import { navigateToUrl } from 'single-spa';
import { useAccountItemGroups } from '../../hooks/useAccountItemGroups';
import useAnalyticsDispatch from '../../hooks/useAnalyticsDispatch';
import useHeaderState from '../../hooks/useHeaderState';
import useMenuItems from '../services-popup/hooks/useMenuItems';
import { usePartnerProductConfiguration } from '../../hooks/usePartnerProductConfiguration';
import { useScrollState } from './utils';
import useServicesPopup from '../../hooks/useServicesPopup';
import useUserContext from '../../hooks/useUserContext';
import { useUserIdentifier } from '../../hooks/useUserIdentifier';

type ViewHeaderProps = {
    onTopCallback?: (onTop: boolean) => void;
};

const ADOTCOM_PARTNER_SWITCHER = 'https://www.asurion.com/myaccount/';
const GLOBAL_HEADER_HEIGHT = 72;

const filterNullProperties = (o: object) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.fromEntries(Object.entries(o).filter(([k, v]) => v !== null));

const ViewHeader: FunctionComponent<ViewHeaderProps> = memo(
    function ViewHeader({ children, onTopCallback }) {
        const rootDivRef = useRef<HTMLDivElement | null>(null);
        const {
            onLogoClick,
            menu,
            homePath,
            accountDropdownConfig,
            subNavConfig,
            view,
        } = useHeaderState();
        const menuItems = useMenuItems();
        const userIdentifier = useUserIdentifier();
        const { open } = useServicesPopup();
        const dispatch = useAnalyticsDispatch('Header', 'click');

        const { partner, filteredPartners } = useUserContext();
        const { header, leftNav } = usePartnerProductConfiguration();
        const leftNavEnabled = !!leftNav?.enabled;

        const accountItemGroups = useAccountItemGroups(
            partner,
            leftNavEnabled,
            accountDropdownConfig ||
                (header.accountNav?.enabled && header.accountNav?.links
                    ? {
                          navLinks: header.accountNav.links.map(
                              filterNullProperties
                          ) as NavLink[],
                      }
                    : undefined),
            filteredPartners
        );

        const isLoggedIn: boolean =
            getAuthenticationState() === AuthenticationState.LoggedIn;

        const getLogoProps = (
            onLogoClick?: () => void,
            homePath?: string
        ): LogoProps => {
            //Avoid showing the partner logo twice (once in header + once in subnav)
            const fullLogoUrl = !subNavConfig?.showPartnerLogo
                ? header.logo.url
                : defaultPartnerProductConfiguration.header.logo.url;

            return {
                imageProps: {
                    src: fullLogoUrl.substring(
                        fullLogoUrl.lastIndexOf('/') + 1
                    ),
                    alt: header.logo.alt,
                },
                clickProps: {
                    onClick: () => {
                        onLogoClick?.();
                        navigateToUrl(homePath || ADOTCOM_PARTNER_SWITCHER);
                    },
                },
            };
        };

        const myAccountProps: ButtonProps = { id: 'aui-my-account-btn' };
        if (!isLoggedIn) {
            myAccountProps.onClick = () =>
                navigateToUrl(new URL(ADOTCOM_PARTNER_SWITCHER).toString());
        }

        const { onTop } = useScrollState(rootDivRef, GLOBAL_HEADER_HEIGHT);
        useEffect(() => {
            onTopCallback?.(onTop);
        }, [onTop, onTopCallback]);

        const [openProfile, setOpenProfile] = useState(false);
        useEffect(() => {
            const closeProfile = () => setOpenProfile(false);
            document.addEventListener('scroll', closeProfile);
            return () => {
                document.removeEventListener('scroll', closeProfile);
            };
        }, [openProfile]);

        let totalHeight = GLOBAL_HEADER_HEIGHT;
        if (view != 'main-view') {
            const backButtonHeight = subNavConfig?.showBackButton ? 40 : 0;
            const partnerLogoHeight =
                header.subNav?.logo && subNavConfig?.showPartnerLogo ? 51 : 0;
            const showTitleOnNewLineHeigh = subNavConfig?.showTitleOnNewLine
                ? 59
                : 0;

            totalHeight +=
                backButtonHeight + partnerLogoHeight + showTitleOnNewLineHeigh;
        }

        return (
            <div data-test-id="ViewHeader">
                <Placeholder data-test-id="Placeholder" height={totalHeight} />
                <SlidingContainer ref={rootDivRef}>
                    <GlobalHeaderContent id="oneservice-global-header-content">
                        <Head height={`${totalHeight}px`}>
                            <GlobalHeader
                                menuItems={[]}
                                logoProps={getLogoProps(onLogoClick, homePath)}
                                myAccountButtonProps={myAccountProps}
                                waffleButtonProps={
                                    !!menu &&
                                    !!menuItems.length &&
                                    header.waffleMenu.enabled
                                        ? {
                                              id: 'aui-waffle-btn',
                                              onClick: () => {
                                                  open();
                                                  dispatch({
                                                      ActionId: 'Menu',
                                                      Cta: 'MenuGrid',
                                                      Section: 'Header',
                                                  });
                                              },
                                          }
                                        : undefined
                                }
                                userIdentifier={userIdentifier}
                                accountItemGroups={accountItemGroups}
                                isLoggedIn={isLoggedIn}
                            />
                        </Head>
                        {children}
                    </GlobalHeaderContent>
                </SlidingContainer>
            </div>
        );
    }
);

export default ViewHeader;

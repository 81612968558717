import { PlanSelectionProps, ServiceItemType } from '../types';
import { initPlanPicker, routeToClaims } from './utils';

import { BasePlanDetails } from '@soluto-private/mx-types';
import TaskListHeartIcon from '../icons/TaskListHeartIcon';
import { UserContextProps } from '../../../providers/UserContextProvider/types';
import { pickPlan } from '../plan-handler';

const title = 'My claims';

export default {
    category: 'repair',
    displayName: title,
    IconComponent: TaskListHeartIcon,
    handlePlanSelection: (planProps: PlanSelectionProps) => {
        const contextProps: UserContextProps = planProps.context;
        const routeToClaimsCallback = (plan: BasePlanDetails) =>
            routeToClaims(
                plan,
                contextProps.partner,
                contextProps.plans,
                contextProps.agreements
            );

        void pickPlan(
            contextProps.plans,
            () => initPlanPicker(planProps, title),
            routeToClaimsCallback
        );
    },
    order: 1,
    actionId: 'ClaimsStatus',
} as ServiceItemType;

import React, { FunctionComponent } from 'react';

const CheckmarkBubbleIcon: FunctionComponent = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.30518 14.0399L11.3122 16.7109L17.0152 8.72695L14.9812 7.27295L10.6852 13.2889L8.69118 11.9599L7.30518 14.0399Z"
            fill="black"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.99797 21H11.998C17.512 21 21.998 16.963 21.998 12C21.998 7.037 17.512 3 11.998 3C6.48397 3 1.99797 7.037 1.99797 12C1.99797 13.735 2.53897 15.392 3.57197 16.836L1.26597 19.32C0.99497 19.611 0.92297 20.035 1.08197 20.4C1.23997 20.764 1.59997 21 1.99797 21ZM11.998 19H4.29097L5.61897 17.57C5.96697 17.195 5.97597 16.618 5.63997 16.232C4.56597 15 3.99797 13.537 3.99797 12C3.99797 8.141 7.58697 5 11.998 5C16.409 5 19.998 8.141 19.998 12C19.998 15.86 16.409 19 11.998 19Z"
            fill="black"
        />
    </svg>
);

export default CheckmarkBubbleIcon;

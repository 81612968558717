import React, { FunctionComponent } from 'react';

const Drill: FunctionComponent = () => (
    <svg
        width="34"
        height="27"
        viewBox="0 0 34 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.3334 7.40002C32.3334 10.7137 29.6471 13.4 26.3334 13.4H14.932C14.2421 13.4 13.5806 13.1257 13.0931 12.6375C12.6056 12.1493 12.3323 11.4873 12.3334 10.7974V3.40002C12.3334 2.29545 13.2288 1.40002 14.3334 1.40002H26.3334C29.6471 1.40002 32.3334 4.08632 32.3334 7.40002Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.33337 8.06671C8.33337 9.53947 9.52728 10.7334 11 10.7334H12.3334V4.06671H11C9.52728 4.06671 8.33337 5.26062 8.33337 6.73338V8.06671Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1.66669 7.42133H8.33335"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.568 13.4C16.568 14.8728 17.7619 16.0667 19.2347 16.0667H22.2587"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M19 1.39734V13.3973"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M20.3334 25.3973H24.3334"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.9734 23.7747C28.0612 24.1697 27.9648 24.5832 27.7115 24.8987C27.4582 25.2142 27.0753 25.3977 26.6707 25.3973H24.3334L21.6667 13.3973H25.6667L27.9734 23.7747Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default Drill;

import React, { FunctionComponent, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import Heading from './Heading';
import { ServiceItemType } from './types';
import ServicesRenderer from './ServicesRenderer';
import StickyBar from './StickyBar';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { menuItemTypeToServiceItem } from './mappings';
import useAnalyticsDispatch from '../../hooks/useAnalyticsDispatch';
import useHeaderState from '../../hooks/useHeaderState';
import useServicesPopup from '../../hooks/useServicesPopup';

export const CONTAINER_ID = 'header-service-pop-up';

const Container = styled.div<{ isActive?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999;
    background-color: ${colors.white};
    overflow-y: auto;
    transform: scale(0);
    transform-origin: top right;
    visibility: hidden;
    border-radius: 10px;
    transition: transform 0.4s, border-radius 0.4s, visibility 0.4s;
    pointer-events: none;

    button {
        appearance: none;
        background: none;
        border: unset;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            transform: scale(1);
            pointer-events: initial;
            border-radius: 0;
            visibility: visible;
        `}

    * {
        box-sizing: border-box;
    }
`;

const ContentContainer = styled.div<{ isActive?: boolean }>`
    padding: 0 13px 100px;
    max-width: 375px;
    margin: 0 auto;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: opacity 0.4s, transform 0.4s;
    ${({ isActive }) =>
        isActive &&
        css`
            transition-delay: 0.4s;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        `}
`;

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

type ServicePopupProps = {
    services: ServiceItemType[];
    isActive?: boolean;
};

const ServicesPopup: FunctionComponent<ServicePopupProps> = ({
    services,
    isActive,
}) => (
    <Container
        isActive={isActive}
        id={CONTAINER_ID}
        data-test-id="MxHeader_ServicesPopUp"
    >
        <StickyBar />
        <ContentContainer isActive={isActive}>
            <Heading />
            <ServicesContainer>
                <ServicesRenderer services={services} />
            </ServicesContainer>
        </ContentContainer>
    </Container>
);

const ServicesPopupRenderer: FunctionComponent = () => {
    const dispatch = useAnalyticsDispatch('Menu', 'view');
    const { menu: isMenuAvailable } = useHeaderState();
    const { isActive, close, menuItems } = useServicesPopup();

    const services = useMemo(
        () => menuItems.map((menuItem) => menuItemTypeToServiceItem[menuItem]),
        [menuItems]
    );

    useEffect(() => {
        if (!isMenuAvailable || !isActive) {
            return;
        }

        dispatch();
    }, [dispatch, isMenuAvailable, isActive]);

    useEffect(() => {
        if (!isActive || !isMenuAvailable) {
            return;
        }

        window.addEventListener('beforeunload', close);

        return () => {
            window.removeEventListener('beforeunload', close);
        };
    }, [isActive, isMenuAvailable, close]);

    if (!isMenuAvailable) {
        return null;
    }

    return <ServicesPopup isActive={isActive} services={services} />;
};

export default ServicesPopupRenderer;

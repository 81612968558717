import { MenuItemContainerProps, MenuItemProps } from '../../types';
import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';

import { DisplaySize } from '../../../../DisplaySize';
import { MenuContent } from './MenuContent';
import { MenuItemButton } from './MenuItemButton';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';

const navMenuTransition = '200ms';
const bezier = 'cubic-bezier(.68,.12,.24,.99)';

export const MenuCardContainer = styled.div<MenuItemContainerProps>`
    ${({ isSelected, isNavMenuActive }) =>
        // NavMenu INITIAL/CLOSED state
        (!isSelected &&
            !isNavMenuActive &&
            css`
                visibility: hidden;
                opacity: 0;
                top: -9999px;
                position: fixed;
                ${DisplaySize.Large} {
                    z-index: 1;
                    transform: scaleX(1) translateY(-3rem);
                    transition: top 0ms ${navMenuTransition} ${bezier},
                        transform ${navMenuTransition} 1500ms ${bezier},
                        opacity ${navMenuTransition} 0ms ${bezier},
                        visibility 0ms ${navMenuTransition} ${bezier};
                    > div > div > div:first-child {
                        opacity: 0;
                        transition: opacity 200ms
                            cubic-bezier(0.68, 0.12, 0.24, 0.99) 200ms;
                    }
                    > div > div > div:nth-child(2) {
                        opacity: 0;
                        transition: opacity 200ms
                            cubic-bezier(0.68, 0.12, 0.24, 0.99) 200ms;
                    }
                }
            `) ||
        // NavMenu OPENED state
        (isSelected &&
            isNavMenuActive &&
            css`
                opacity: 1;
                visibility: visible;
                position: relative;
                ${DisplaySize.Large} {
                    top: calc(4.5rem - 1px);
                    transform: scaleX(1) translateY(0);
                    transition: top 0ms,
                        transform ${navMenuTransition} ${bezier},
                        opacity 500ms ${bezier}, visibility 0ms;
                    z-index: 2;
                    > div > div > div:first-child {
                        opacity: 1;
                        transition: opacity 150ms
                            cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
                    }
                    > div > div > div:nth-child(2) {
                        opacity: 1;
                        transition: opacity 300ms
                            cubic-bezier(0.68, 0.12, 0.24, 0.99) 100ms;
                    }
                }
            `) ||
        // NavMenu TRANSITION state
        (!isSelected &&
            isNavMenuActive &&
            css`
                visibility: hidden;
                top: -9999px;
                opacity: 1;
                position: fixed;
                ${DisplaySize.Large} {
                    transform: scaleX(1) translateY(0);
                    z-index: 1;
                    > div > div > div:first-child {
                        opacity: 0;
                        transition: opacity 150ms
                            cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
                    }
                    > div > div > div:nth-child(2) {
                        opacity: 0;
                        transition: opacity 300ms
                            cubic-bezier(0.68, 0.12, 0.24, 0.99) 100ms;
                    }
                }
            `)}
    padding: 1rem;
    box-sizing: border-box;
    background-color: transparent;
    border-bottom: solid 1px ${colors.neutralBrighter};
    padding-block: 0.5rem 2rem;
    ${({ item }) =>
        item &&
        item.id === 'globalNav_subnav' &&
        css`
            padding: 0rem;
            padding-block: 0rem;
            border-bottom: 0px;
        `}
    ${DisplaySize.Large} {
        border: none;
        position: fixed;
        left: 0;
        right: 0;
        max-width: 1060px;
        padding: 1rem;
        margin: auto;
    }
`;

export const StyledMenuItem = styled.li<MenuItemContainerProps>`
    list-style: none;
    display: flex;
    align-items: stretch;
    &:hover button {
        opacity: 1;
    }
    &::before {
        height: 3px;
        content: '';
        position: absolute;
        z-index: 9;
        bottom: 0;
        background: transparent;
    }

    ${({ isSelected, isNavMenuActive }) =>
        isNavMenuActive &&
        isSelected &&
        css`
            &&& {
                button {
                    opacity: 1;
                }
                ${DisplaySize.Small} {
                    border: none;
                }
            }
        `}
    ${DisplaySize.Small} {
        height: initial;
        flex-direction: column;
        border-bottom: solid 1px ${colors.neutralBrightest};
        ${({ item }) =>
            item &&
            item.id === 'globalNav_subnav' &&
            css`
                background-color: ${colors.neutralBrightest};
            `}
    }
    ${DisplaySize.Large} {
        ${({ item }) =>
            item &&
            item.id === 'globalNav_subnav' &&
            css`
                display: none;
            `}
    }
`;

export const MenuItem: FC<MenuItemProps> = ({
    item,
    hasContent,
    onMouseEnter,
    isNavMenuActive,
    isMenuActive,
    isSelected,
    onKeyDown,
    onClick,
    closeNavMenu,
    focusItem,
    itemRef,
}) => {
    const ref = useRef<HTMLButtonElement>(null);
    return (
        <StyledMenuItem
            role="menuitem"
            onMouseEnter={onMouseEnter}
            isSelected={isSelected}
            isNavMenuActive={isNavMenuActive}
            isMenuActive={isMenuActive}
            aria-haspopup={hasContent}
            item={item}
        >
            <MenuItemButton
                data={item}
                isSelected={isSelected}
                isNavMenuActive={isNavMenuActive}
                isMenuActive={isMenuActive}
                hasContent={hasContent}
                onClick={onClick}
                onKeyDown={onKeyDown}
                innerRef={ref}
            />
            <MenuCardContainer
                role="menu"
                isSelected={isSelected}
                isNavMenuActive={isNavMenuActive}
                isMenuActive={isMenuActive}
                item={item}
            >
                <div>
                    <MenuContent
                        item={item}
                        closeMegaMenu={closeNavMenu}
                        focusParent={() => ref.current?.focus()}
                        focusItem={focusItem}
                        itemRef={itemRef}
                    />
                </div>
            </MenuCardContainer>
        </StyledMenuItem>
    );
};

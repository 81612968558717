import { AccountMenuContainerProps, AccountMenuProps } from './types';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { AccountItems } from './AccountItems';
import { DisplaySize } from '../../DisplaySize';
import { ProfileDetails } from './ProfileDetails';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';

const AccountMenuContainer = styled.div<AccountMenuContainerProps>`
    position: absolute;
    width: 292px;
    right: 16px;
    top: 65px;
    background-color: ${colors.white};
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 1rem;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    pointer-events: none;
    visibility: hidden;

    ${({ isActive }) =>
        isActive &&
        css`
            pointer-events: auto;
            visibility: visible;
        `}

    ${({ showClaimsButton }) =>
        showClaimsButton &&
        css`
            right: 134px;
        `}

  ${DisplaySize.Small} {
        position: static;
        background-color: transparent;
        box-shadow: none;
        width: -webkit-fill-available;
        padding: 10px;
    }
`;

export const AccountMenu: FC<AccountMenuProps> = ({
    itemGroups,
    userIdentifier,
    closeMenu,
    ...rest
}) => (
    <AccountMenuContainer
        data-test-id="my-account-menu"
        aria-label="My Account menu"
        {...rest}
    >
        {userIdentifier && <ProfileDetails userIdentifier={userIdentifier} />}
        <AccountItems itemGroups={itemGroups} onClick={closeMenu} />
    </AccountMenuContainer>
);

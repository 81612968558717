import React, { FC } from 'react';

import { DisplaySize } from '../../DisplaySize';
import { LogoImagePropsV2 } from './types';
import styled from 'styled-components';

const LOGOS_URL = `https://assets.asurion-ui.my.asurion53.com/images/logos`;

const StyledImage = styled.img`
    display: block !important;
    height: 48px;

    ${DisplaySize.Small} {
        height: 40px;
    }
`;

export const LogoImage: FC<LogoImagePropsV2> = (props) => {
    return (
        <StyledImage
            {...props}
            src={`${LOGOS_URL}/${props.src}`}
            alt={props.alt || 'logo'}
        />
    );
};

import React, { FC } from 'react';
import {
    Text,
    colors,
    fontFamily,
    getFontSize,
} from '@soluto-private/mx-asurion-ui-react-v3';

import { AccountItemGroupProps } from './types';
import { DisplaySize } from '../../DisplaySize';
import styled from 'styled-components';

const MenuItem = styled.button`
    display: block;
    border: none;
    background-color: transparent;
    text-align: left;
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    color: ${colors.neutralDeeper};
    height: 37px;

    &:hover {
        background-color: ${colors.neutralBrightest};
        border-radius: 8px;
    }
    &:active {
        background-color: ${colors.neutralBrighter};
        border-radius: 8px;
    }

    ${DisplaySize.Small} {
        font-size: ${getFontSize(3)};
        padding: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }
`;

const MarginlessDivider = styled.div`
    padding: 8px 12px;
`;

const Line = styled.div`
    height: 1px;
    background-color: ${colors.neutralBright};
`;

const ItemText = styled(Text)`
    font-family: ${fontFamily};
    ${DisplaySize.Small} {
        height: 26px;
        font-size: ${getFontSize(3)};
    }
`;

export const AccountItemGroup: FC<AccountItemGroupProps> = ({
    id: groupId,
    items,
    textColor = colors.neutralDeeper,
    onClick,
}) => (
    <div id={groupId}>
        <MarginlessDivider>
            <Line />
        </MarginlessDivider>
        {items?.map(({ name, id, onClick: onClickItem }) => (
            <MenuItem
                key={`NavItem-${groupId}-${id}`}
                tabIndex={0}
                onClick={(e) => {
                    onClickItem();
                    onClick(e);
                }}
                id={id}
            >
                <ItemText color={textColor} weight="base">
                    {name}
                </ItemText>
            </MenuItem>
        ))}
    </div>
);

import {
    AuthenticationState,
    getAuthenticationState,
    getUser,
} from '@soluto-private/mx-app-authentication';

export const getPrincipalIdentifier = async () => {
    const isLoggedIn =
        getAuthenticationState() === AuthenticationState.LoggedIn;
    if (!isLoggedIn) return '';

    const user = await getUser();
    return user?.profile.principal_identifier || '';
};

import { Header, Title } from './commonStyles';
import React, { FC } from 'react';
import {
    colors,
    getFontSize,
    getFontWeight,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled, { css } from 'styled-components';

import { DisplaySize } from '../../DisplaySize';
import { NavCardContainer } from './NavCardContainer';

type SubNavItemButtonTypes = {
    title?: string;
    href?: string;
} & React.ComponentPropsWithoutRef<'a'>;

export const Container = styled(NavCardContainer)`
    align-items: center;
    height: 3.625rem;
    background-color: ${colors.neutralBrightest};
    padding-left: 16px;
    border: none;
    h4 {
        font-weight: ${getFontWeight('base')};
    }
    ${({ href }) =>
        href &&
        css`
            color: ${colors.primaryDeep};
            h4 {
                font-weight: ${getFontWeight('heavy')};
            }
        `}
    justify-content: flex-start;
    flex-direction: column;
    ${DisplaySize.Small} {
        justify-content: flex-start;
        flex-direction: row;
    }
    &:hover {
        background-color: ${colors.neutralBrighter};
    }
    &:focus {
        h4 {
            font-weight: ${getFontWeight('heavy')};
        }
    }
    &:focus-visible {
        box-shadow: 0px 0px 0px 2px ${colors.black} inset;
    }
`;

export const HeaderExtended = styled(Header)`
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem;
    ${DisplaySize.Small} {
        padding: 0;
        width: calc(100% - 108px);
    }
`;

export const TitleExtended = styled(Title)`
    margin: 0.5rem 0;
    text-align: left;
    ${DisplaySize.Small} {
        font-size: ${getFontSize(2)};
    }
`;

export const NavCardText: FC<SubNavItemButtonTypes> = ({
    title,
    href,
    ...restProps
}) => (
    <Container
        role="menuitem"
        href={href}
        as={href ? 'a' : 'button'}
        {...restProps}
    >
        <HeaderExtended>
            <TitleExtended>{title}</TitleExtended>
        </HeaderExtended>
    </Container>
);

import React, { FunctionComponent } from 'react';

import ServiceItem from './ServiceItem';
import { ServiceItemType } from './types';
import styled from 'styled-components';

const Container = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

type Props = {
    services: ServiceItemType[];
};

const ServiceList: FunctionComponent<Props> = ({ services }) => {
    return (
        <Container>
            {services
                .sort(({ order }) => order)
                .map((service, index) => (
                    <ServiceItem key={index} {...service} />
                ))}
        </Container>
    );
};

export default ServiceList;

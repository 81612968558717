import CloudImage from '../icons/CloudImage';
import { ServiceItemType } from '../types';

const assertEnvVar = <T>(name: string, value?: T): T => {
    if (!value) {
        throw new Error(`Missing env var: ${name}`);
    }
    return value;
};

const partnerPhotosMapping: Record<string, string> = {
    cox: new URL(assertEnvVar('PHOTOS_URL', process.env.PHOTOS_URL)).href,
    default: '/gallery',
};

export default {
    category: 'optimize',
    displayName: 'Photo storage',
    IconComponent: CloudImage,
    targetUrl: ({ partner }) =>
        partnerPhotosMapping[partner] || partnerPhotosMapping.default,
    order: 0,
    actionId: 'GoToPhotosBackup',
} as ServiceItemType;

import React, { FC } from 'react';
import {
    colors,
    getFontSize,
    getFontWeight,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled, { css } from 'styled-components';

import { ChevronArrow } from './ChevronArrow';
import { DisplaySize } from '../../../../../DisplaySize';
import { MenuItemButtonProps } from './types';

export const StyledMenuItemButton = styled.button<
    Pick<MenuItemButtonProps, 'isSelected' | 'isMenuActive' | 'isNavMenuActive'>
>`
    background: none;
    border: none;
    height: 3.625rem;
    font-family: Apercu;
    font-size: ${getFontSize(2)};
    display: flex;
    gap: 0.125rem;
    padding: 1rem;
    align-items: center;
    font-weight: 400;
    transition: 200ms 0ms opacity;
    opacity: 1;
    color: ${colors.black};
    cursor: pointer;
    ${DisplaySize.Large} {
        height: 4.5rem;
        span {
            opacity: 1;
            transition: opacity 200ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
            ${({ isSelected, isMenuActive }) =>
                !isSelected &&
                isMenuActive &&
                css`
                    color: ${colors.neutralDeeper};
                `}
        }
    }

    &:focus-visible {
        box-shadow: 0px 0px 0px 2px ${colors.black} inset;
        outline: none;
        border-radius: 0.5rem;
    }

    ${DisplaySize.Small} {
        align-self: stretch;
        padding-block: 1rem;
        display: flex;
        justify-content: space-between;
        font-size: ${getFontSize(3)};
        > img {
            height: auto;
            max-height: 32px;
        }

        ${({ isSelected, isMenuActive }) =>
            isSelected &&
            isMenuActive &&
            css`
                font-weight: ${getFontWeight('heavy')};
                svg {
                    stroke-linejoin: miter;
                    stroke-width: 1px;
                    stroke: ${colors.black};
                }
            `}
        ${({ isSelected, isNavMenuActive }) =>
            !isSelected &&
            isNavMenuActive &&
            css`
                opacity: 1;
            `}
    }
`;

export const MenuItemButton: FC<MenuItemButtonProps> = ({
    data,
    isSelected,
    isNavMenuActive,
    isMenuActive,
    hasContent,
    onClick,
    onKeyDown,
    innerRef,
}) => (
    <StyledMenuItemButton
        ref={innerRef}
        id={data.id}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-expanded={isSelected}
        isSelected={isSelected}
        isNavMenuActive={isNavMenuActive}
        isMenuActive={isMenuActive}
    >
        {data.imageProps?.src ? (
            <img {...data.imageProps} alt={data.imageProps.alt || data.title} />
        ) : (
            <span>{data.title}</span>
        )}

        {hasContent && (
            <ChevronArrow isSelected={isSelected} isMenuActive={isMenuActive} />
        )}
    </StyledMenuItemButton>
);

import React, { FunctionComponent } from 'react';

const CloudSmartPhoneUpload: FunctionComponent = () => (
    <svg
        width="34"
        height="28"
        viewBox="0 0 34 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M29.0416 16.4999C31.2661 15.6134 32.6605 13.6666 32.5833 11.555C32.5565 9.99244 31.7935 8.50651 30.4698 7.43885C29.1461 6.37119 27.3755 5.81365 25.5651 5.89443C23.951 3.09376 20.6276 1.33003 17 1.34888C12.0565 1.29171 7.91454 4.55963 7.54372 8.80959C6.0679 8.52986 4.52433 8.83518 3.33758 9.64156C2.15084 10.4479 1.44589 11.6705 1.41664 12.9729C1.29883 14.8765 2.79818 16.5733 4.95831 16.9811"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.75 13.5615H21.25C22.0324 13.5615 22.6667 14.1083 22.6667 14.7828V25.7742C22.6667 26.4487 22.0324 26.9954 21.25 26.9954H12.75C11.9676 26.9954 11.3334 26.4487 11.3334 25.7742V14.7828C11.3334 14.1083 11.9676 13.5615 12.75 13.5615Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17 23.6372C16.8044 23.6372 16.6459 23.7739 16.6459 23.9425C16.6459 24.1111 16.8044 24.2478 17 24.2478C17.1956 24.2478 17.3542 24.1111 17.3542 23.9425C17.3542 23.7739 17.1956 23.6372 17 23.6372V23.6372"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17 11.1188V4.40186"
            stroke="black"
            stroke-width="1.41667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M20.5417 7.45502L17 4.40186L13.4584 7.45502"
            stroke="black"
            stroke-width="1.41667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default CloudSmartPhoneUpload;

import { Button, Title } from './styles';
import React, { VoidFunctionComponent } from 'react';

import { BackButtonConfig } from './types';
import LeftArrowIcon from './LeftArrowIcon';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';

type BackButtonProps = {
    onTop: boolean;
} & BackButtonConfig;

const BackButton: VoidFunctionComponent<BackButtonProps> = ({
    onTop,
    title,
    onBack,
}) => {
    return (
        <Button
            aria-label="back button"
            data-test-id="MxHeader_BackButton"
            onClick={onBack}
        >
            <LeftArrowIcon />
            <Title id="mx-back-title" show={onTop}>
                <Text size={2}>{title}</Text>
            </Title>
        </Button>
    );
};

export default BackButton;

import { Header, Title } from './commonStyles';
import React, { FC } from 'react';

import { NavCardContainer } from './NavCardContainer';
import { NavCardProps } from './types';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const Image = styled.img`
    width: 5rem;
    margin-top: 0.5rem;
`;

const ContainerExtended = styled(NavCardContainer)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
        box-shadow: 0px 0px 0px 1px ${colors.black} inset;
    }
`;

const HeaderExtended = styled(Header)`
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
`;

const TitleExtended = styled(Title)`
    margin-bottom: 0.5rem;
    margin-top: 0;
`;

export const NavCardDevice: FC<NavCardProps> = ({
    title,
    imgSrc,
    ...restProps
}) => (
    <ContainerExtended {...restProps}>
        <Image src={imgSrc} alt={title} />
        <HeaderExtended>
            <TitleExtended>{title}</TitleExtended>
        </HeaderExtended>
    </ContainerExtended>
);

import { aeSdk, openSdk } from '../ae-sdk-handler';

import CloudSmartPhoneUpload from '../icons/CloudSmartPhoneUpload';
import { ServiceItemType } from '../types';

export default {
    category: 'solutions',
    displayName: 'Remote backup with expert',
    IconComponent: CloudSmartPhoneUpload,
    onClick: () => {
        void aeSdk(openSdk, { text: 'Set up my device' });
    },
    order: 0,
    actionId: 'GoToTechSupport',
} as ServiceItemType;

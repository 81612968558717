import { BasePlanDetails, ProductType } from '@soluto-private/mx-types';
import { PlanSelectionProps, ServiceItemType } from '../types';
import { aeSdk, openSdk, overrideExpertise } from '../ae-sdk-handler';

import GroupedExpertsIcon from '../icons/GroupedExpertsIcon';
import { UserContextProps } from '../../../providers/UserContextProvider/types';
import { i18n } from '../../../initializations';
import { pickPlan } from '../plan-handler';

export const openExpertSupport = async (
    plan: BasePlanDetails
): Promise<void> => {
    try {
        switch (plan.productType) {
            case ProductType.Home:
                await aeSdk(overrideExpertise, {
                    expertise: ['hometech-protection'],
                });
                await aeSdk(openSdk);
                break;
            case ProductType.Mobility:
                await aeSdk(overrideExpertise, {
                    expertise: ['general-conversation'],
                });
                await aeSdk(openSdk);
                break;
            default:
                console.error(
                    `${JSON.stringify(
                        plan
                    )} is not handled by expert-support menu item`
                );
        }
    } catch (e) {
        console.error(e);
    }
};

const title = 'Expert support';

export default {
    category: 'support',
    displayName: title,
    IconComponent: GroupedExpertsIcon,
    handlePlanSelection: (planProps: PlanSelectionProps) => {
        const contextProps: UserContextProps = planProps.context;
        const initPicker = () => {
            planProps.configurePicker({
                title: i18n.get(title),
                subtitle: `${i18n.get('Start by selecting your plan')}:`,
                options: contextProps.plans.map((p) => ({
                    label: p.displayName,
                    value: p,
                })),
                button: {
                    onClickCallback: (o) => {
                        void openExpertSupport(o.value as BasePlanDetails);
                    },
                    children: i18n.get('Continue'),
                },
            });
            planProps.showPicker(true);
        };

        void pickPlan(contextProps.plans, initPicker, openExpertSupport);
    },
    order: 0,
    actionId: 'GoToExpertSupport',
} as ServiceItemType;

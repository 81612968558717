import React, { FunctionComponent } from 'react';

const HouseTool: FunctionComponent = () => (
    <svg
        width="36"
        height="34"
        viewBox="0 0 36 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.95831 18.1687V31.627C5.95831 32.0182 6.27544 32.3354 6.66665 32.3354H29.3333C29.7245 32.3354 30.0416 32.0182 30.0416 31.627V18.877"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1.70831 17.4604L17.15 2.0187C17.3742 1.7943 17.6785 1.66821 17.9957 1.66821C18.313 1.66821 18.6172 1.7943 18.8415 2.0187L34.2831 17.4604"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M20.125 29.5021V21.9413C22.3695 20.877 23.4801 18.3161 22.7231 15.9502L21.0231 17.6346C20.3288 18.3236 19.2074 18.3191 18.5185 17.6247C17.8296 16.9303 17.834 15.809 18.5284 15.1201L20.4919 13.175C18.9176 12.2588 16.9677 12.2796 15.4133 13.2291C13.8589 14.1786 12.9502 15.9039 13.0466 17.7229C13.143 19.5418 14.2289 21.1614 15.875 21.9413V29.5021"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default HouseTool;

import { Icon, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import React, { VoidFunctionComponent } from 'react';

const LeftArrowIcon: VoidFunctionComponent = () => {
    return (
        <Icon src="ArrowsNavigationHeader" fill={colors.black} size="medium" />
    );
};

export default LeftArrowIcon;

import { ContentfulCollectionQuery } from './types';
import { getEntryCollection } from './getEntryCollection';
import { useAsync } from 'react-async-hook';
import { useContentfulContext } from './ContentfulContext';

export const useContentfulItems = <P extends Record<string, unknown>>(
    query: ContentfulCollectionQuery<P>
) => {
    const { config } = useContentfulContext();

    return useAsync(async () => {
        const content = await getEntryCollection(config, query);

        return content.items.map((item) => ({
            ...(query.defaultProps ?? {}),
            ...(item.fields as P),
        }));
    }, [config, query]);
};

import { ContentfulConfig, ContentfulContentQuery } from './types';
import type { EntryCollection, EntrySkeletonType } from 'contentful';

export const getEntryCollection = async <T extends EntrySkeletonType>(
    { spaceId, environment, accessToken }: ContentfulConfig,
    { type, tags = [], include = 10 }: ContentfulContentQuery
): Promise<EntryCollection<T>> => {
    const allTags = tags.filter((a): a is string => a !== undefined).join(',');

    const url = `https://cdn.contentful.com/spaces/${spaceId}/environments/${environment}`;
    const params = new URLSearchParams({
        content_type: type,
        'metadata.tags.sys.id[all]': allTags,
        include: `${include}`,
    });

    const response = await fetch(`${url}/entries?${params.toString()}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    return (await response.json()) as EntryCollection<T>;
};

import React, {
    FunctionComponent,
    createContext,
    useEffect,
    useState,
} from 'react';
import { defaultContext, getContext } from './utils';

import { UserContextProps } from './types';
import { storageEvents } from '@soluto-private/mx-context';

export const UserContext = createContext<UserContextProps>(defaultContext);

const UserContextProvider: FunctionComponent = ({ children }) => {
    const [context, setContext] = useState<UserContextProps>(defaultContext);

    useEffect(() => {
        const handleContextUpdated = () => {
            setContext(getContext());
        };

        handleContextUpdated();

        document.addEventListener(
            storageEvents.contextUpdated,
            handleContextUpdated
        );

        return () => {
            document.removeEventListener(
                storageEvents.contextUpdated,
                handleContextUpdated
            );
        };
    }, []);

    return (
        <UserContext.Provider value={context}>{children}</UserContext.Provider>
    );
};

export default UserContextProvider;

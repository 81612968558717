import { Link, LinkProps } from '@soluto-private/mx-asurion-ui-react-v3';
import React, { RefCallback } from 'react';

import { ButtonProps } from '@soluto-private/mx-asurion-ui-react-v3';
import { LogoProps } from './components/Logo';
import styled from 'styled-components';

export type ClaimsButtonProps = LinkProps & {
    label?: string;
    isMyAccountMenuActive?: boolean;
};

export const StyledClaimsButton = styled(Link)`
    height: 40px;
    width: 100%;
`;

export type GlobalHeaderInnerProps = {
    logoProps?: LogoProps;
    claimsButtonProps?: ClaimsButtonProps;
    myAccountButtonProps?: ButtonProps;
    waffleButtonProps?: ButtonProps;
    menuItems?: MenuItemConfig[];
    isMarketingMenu?: boolean;
    isLoggedIn?: boolean;
    accountItemGroups?: AccountItemGroupConfig[];
    userIdentifier?: string;
};

export type GlobalHeaderState = {
    isNavMenuActive: boolean;
    isMyAccountMenuActive: boolean;
    setNavMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
    setMyAccountMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
    closeMenus: () => void;
};

export type MenuItemConfig = ContentfulMenuItemConfig | CustomMenuItemConfig;

export type MenuItemConfigBase = {
    id: string;
    title: string;
    hasContent: boolean;
    imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
};

export type ContentfulMenuItemConfig = MenuItemConfigBase & {
    menuName: string;
};

export type CustomMenuItemConfig = MenuItemConfigBase & {
    content: React.ComponentType<MenuContentBaseProps>;
};

export const isContentfulMenuItem = (
    item: MenuItemConfig
): item is ContentfulMenuItemConfig =>
    !!(item as ContentfulMenuItemConfig).menuName;

export const isCustomMenuItem = (
    item: MenuItemConfig
): item is CustomMenuItemConfig => !!(item as CustomMenuItemConfig).content;

export type MenuContentBaseProps = {
    closeMegaMenu: () => void;
    focusParent: () => void;
    focusItem: (itemIndex: number) => void;
    itemRef: (itemIndex: number) => RefCallback<HTMLElement>;
};

export type MyAccountItem = {
    name: string;
    id: string;
    onClick: () => void;
};

export type AccountItemGroupConfig = {
    id: string;
    items: MyAccountItem[];
};

export type LogoAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type LogoImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    src?: string;
};

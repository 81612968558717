import { Button, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { ButtonProps, NavCardProps } from './types';
import { Header, Title } from './commonStyles';
import React, { FC } from 'react';

import { NavCardContainer } from './NavCardContainer';
import styled from 'styled-components';

const ContainerExtended = styled(NavCardContainer)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
        box-shadow: 0px 0px 0px 1px ${colors.black} inset;
    }
`;

const HeaderExtended = styled(Header)`
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
`;

const TitleExtended = styled(Title)`
    margin-bottom: 0.5rem;
    margin-top: 0;
`;

export const ButtonContainer = styled(Button)<ButtonProps>`
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
`;

export const NavCardButton: FC<NavCardProps> = ({ title, ...restProps }) => (
    <ContainerExtended {...restProps}>
        <ButtonContainer
            iconSrc="ArrowRight"
            variant="outline"
            color="secondary"
            tabIndex={-1}
        />
        <HeaderExtended>
            <TitleExtended>{title}</TitleExtended>
        </HeaderExtended>
    </ContainerExtended>
);

import { Button, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled, { css } from 'styled-components';

import { DisplaySize } from './DisplaySize';

export const Header = styled.header<{
    showHeader: boolean;
    depthOffset: number;
    isSubNav: boolean;
}>`
    position: sticky;
    top: ${({ showHeader }) => (showHeader ? 0 : '-85px')};
    border-bottom: ${({ isSubNav }) =>
        isSubNav ? 'none' : `solid 1px ${colors.neutralBrighter}`};
    left: 0;
    width: 100%;
    background: ${colors.white};
    display: flex;
    align-items: center;
    z-index: ${({ depthOffset }) => 11 + depthOffset};
    transition: top 0.2s ease-in-out;
`;

export const Branding = styled.div`
    position: relative;
    padding-block: 0.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const MenuButton = styled(Button)`
    display: flex;
    align-items: center;
    border: unset;
    padding: 0.5rem;

    span {
        display: block; // This is needed to prevent the span from adding a tiny additional space beneath the 'X' icon
    }
`;

export const Container = styled.div`
    margin: auto;
    max-width: 1440px;
    padding: 0 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    background: ${colors.white};
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    ${DisplaySize.Small} {
        flex-direction: column;
        padding: 0 0.5rem;
    }
`;

export const NavContainer = styled.div<{
    isMenuActive: boolean;
}>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    ${DisplaySize.Small} {
        overflow-y: auto;
        background: ${colors.white};
        right: 0;
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        top: 4.0625rem;
        transform: translateY(-429vw);
        opacity: 0;
        position: absolute;
        align-items: flex-start;
        height: calc(100vh - 4.0625rem);
        max-width: 429px;
        pointer-events: none;
        ${({ isMenuActive }) =>
            isMenuActive &&
            css`
                transform: translateX(0vw);
                transition: all 10ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
                opacity: 1;
                pointer-events: auto;
            `}
    }
`;

export const Actions = styled.nav<{
    isMyAccountMenuActive: boolean;
}>`
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;

    ${DisplaySize.Small} {
        padding: 1rem;
        padding-block-start: 2rem;
        margin-bottom: 3rem;
    }

    ${({ isMyAccountMenuActive }) =>
        isMyAccountMenuActive &&
        css`
            ${DisplaySize.Small} {
                padding-block-start: 1rem;
            }
        `}
`;

export const Overlay = styled.div<{
    isActive: boolean;
    depthOffset: number;
}>`
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    z-index: ${({ depthOffset }) => 10 + depthOffset};
    transform: ${({ isActive }) =>
        isActive ? 'translateX(0vw)' : 'translateX(-200vw)'};
    opacity: 0.5;
    display: block;
`;

import React, { FC } from 'react';

import { DisplaySize } from '../../DisplaySize';
import { LogoClickProps } from './types';
import styled from 'styled-components';

const LogoButton = styled.button`
    height: 48px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: none;

    ${DisplaySize.Small} {
        height: 40px;
    }
`;

const LogoAnchor = styled.a`
    ${DisplaySize.Small} {
        display: inline;
        vertical-align: baseline;
        line-height: normal;
        font-size: 1rem;
    }
`;
export const LogoClickableWrapper: FC<LogoClickProps> = (props) => {
    const buttonProps = props as React.ButtonHTMLAttributes<HTMLButtonElement>;
    const anchorProps = props as React.AnchorHTMLAttributes<HTMLAnchorElement>;

    return buttonProps?.onClick ? (
        <LogoButton {...buttonProps} />
    ) : anchorProps?.href ? (
        <LogoAnchor {...anchorProps} />
    ) : (
        <>{props.children}</>
    );
};

import { ActiveUnderline, ActiveUnderlineStyleProps } from '../types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ScreenSize, useScreenSize } from '../../../hooks/useScreenSize';
import styled, { css } from 'styled-components';

import { DisplaySize } from '../../../DisplaySize';
import { Key } from '../../../utils';
import { MenuItem } from './MenuItem';
import { MenuItemConfig } from '../../../types';
import { NavMenuBackgroundProps } from './types';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';

const StyledNav = styled.nav`
    position: relative;
    margin: auto;

    ${DisplaySize.Small} {
        flex-direction: column;
        width: 100%;
        margin: initial;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        position: relative;
        flex-direction: row;
        ${DisplaySize.Small} {
            flex-direction: column;
        }
    }
`;

const StyledActiveUnderline = styled.div<ActiveUnderlineStyleProps>`
    position: absolute;
    bottom: 0px;
    width: 0;
    height: 0px;
    opacity: 0;
    transition: transform 0ms 0ms;
    ${({ isMenuActive, width, xPos }) =>
        isMenuActive &&
        css`
            background: ${colors.black};
            transform: translateX(${xPos}px);
            width: ${width}px;
            opacity: 1;
            height: 2px;
            transition: width 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms,
                transform 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms,
                opacity 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
        `}

    ${DisplaySize.Small} {
        display: none;
    }
`;

const NavMenuBackground = styled.div<NavMenuBackgroundProps>`
    background: ${colors.white};
    box-shadow: 0px 20px 20px rgb(0 0 0 / 5%);
    width: 100vw;
    left: 0;
    z-index: 0;
    right: 0;
    position: fixed;
    margin: auto;
    max-width: 1060px;
    opacity: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    top: -9999px;
    opacity: 0;
    height: 0px;
    transform: scaleX(0.5);
    transition: top 0ms 300ms,
        opacity 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms,
        height 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms,
        transform 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
    ${({ isMenuActive, height }) =>
        isMenuActive &&
        css`
            top: calc(4.5rem + 1px);
            height: ${height}px;
            opacity: 1;
            transform: scaleX(1);
            transition: top 0ms 0ms,
                opacity 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms,
                height 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms,
                transform 300ms cubic-bezier(0.68, 0.12, 0.24, 0.99) 0ms;
        `}
    ${DisplaySize.Small} {
        display: none;
    }
`;

export type NavMenuProps = {
    items: MenuItemConfig[];
    isNavMenuActive: boolean;
    setNavMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavMenu: FC<NavMenuProps> = ({
    items,
    isNavMenuActive,
    setNavMenuActive,
}) => {
    const [menuHeight, setMenuHeight] = useState<number>(0);
    const [activeUnderline, setActiveUnderline] = useState<ActiveUnderline>({
        width: 0,
        xPos: 0,
    });
    const [selectedItemId, setSelectedItemId] = useState<string>();

    const isSelected = (item: MenuItemConfig) => item.id === selectedItemId;

    const screenSize = useScreenSize();

    const openMenuItem = (item: MenuItemConfig) => {
        if (item.id !== selectedItemId) {
            updateActiveUnderline(item.id);
            updateMenuBackground(item.id);
        }

        setSelectedItemId(item.id);
        setNavMenuActive(item.hasContent);
    };

    const closeItem = () => {
        setActiveUnderline({
            width: 0,
            xPos: 0,
        });
        setSelectedItemId(undefined);
        setNavMenuActive(false);
        setMenuHeight(0);
    };

    const itemsRef = useRef<Record<string, HTMLElement[]>>({});
    const itemRef = useCallback(
        (itemId: string) => (itemIndex: number) => (e: HTMLElement) => {
            if (itemsRef.current[itemId] === undefined) {
                itemsRef.current[itemId] = [];
            }
            itemsRef.current[itemId][itemIndex] = e;
        },
        []
    );
    const focusItem = useCallback(
        (itemId: string) => (itemIndex: number) =>
            itemsRef.current[itemId]?.[itemIndex]?.focus(),
        []
    );

    const toggleItem = (item: MenuItemConfig) =>
        item.id === selectedItemId ? closeItem() : openMenuItem(item);

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
        item: MenuItemConfig
    ) => {
        switch (e.nativeEvent.code) {
            case Key.Escape:
                closeItem();
                break;
            case Key.Space:
            case Key.Enter:
                e.preventDefault();
                isNavMenuActive && isSelected(item)
                    ? closeItem()
                    : openMenuItem(item);
                break;
            case Key.ArrowDown:
                e.preventDefault();
                if (isNavMenuActive && isSelected(item)) {
                    focusItem(item.id)(0);
                }
                break;
        }
    };

    const handleMouseEnter = useCallback(
        (item: MenuItemConfig) => () => {
            if (screenSize === ScreenSize.Large) {
                openMenuItem(item);
            }
        },
        [openMenuItem, items]
    );

    const handleMouseLeave = () => {
        if (screenSize === ScreenSize.Large) {
            closeItem();
        }
    };

    const handleClick = (item: MenuItemConfig) => {
        if (item) {
            toggleItem(item);
        }
    };

    const updateMenuBackground = (itemId: string) => {
        const el = document.querySelector<HTMLElement>('#' + itemId);
        const menuEl = el?.nextElementSibling;
        if (menuEl) {
            const elHeight = (menuEl as HTMLElement).offsetHeight;
            setMenuHeight(elHeight);
        }
    };

    const updateActiveUnderline = (itemId: string) => {
        const el = document.querySelector<HTMLElement>('#' + itemId);
        if (el) {
            const elWidth = el.offsetWidth - 32;
            const elXPos = el.offsetLeft + 16;
            setActiveUnderline({
                width: elWidth,
                xPos: elXPos,
            });
        }
    };

    useEffect(() => {
        if (selectedItemId) {
            const item = document.getElementById(selectedItemId);

            item?.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
                inline: 'start',
            });
        }
    }, [selectedItemId]);

    return (
        <StyledNav aria-label="Main menu" onMouseLeave={handleMouseLeave}>
            {items && (
                <ul role="menu">
                    {items.map((i) => (
                        <MenuItem
                            item={i}
                            key={i.id}
                            hasContent={i.hasContent}
                            isSelected={isSelected(i)}
                            isNavMenuActive={isNavMenuActive}
                            isMenuActive={false}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            onMouseEnter={() => handleMouseEnter(i)}
                            onClick={() => handleClick(i)}
                            openMenuItem={openMenuItem}
                            closeNavMenu={closeItem}
                            focusItem={focusItem(i.id)}
                            itemRef={itemRef(i.id)}
                        />
                    ))}
                </ul>
            )}
            <StyledActiveUnderline
                width={activeUnderline.width}
                xPos={activeUnderline.xPos}
                isMenuActive={isNavMenuActive}
            />
            <NavMenuBackground
                isMenuActive={isNavMenuActive}
                height={menuHeight}
            />
        </StyledNav>
    );
};

import * as S from './HeaderStyles';

import { ContentfulConfig, ContentfulProvider } from './contentful';
import React, { FC, forwardRef, useCallback, useEffect, useState } from 'react';
import { ScreenSize, useScreenSize } from './hooks/useScreenSize';
import { SubNav, SubNavProps } from './components/SubNav';
import {
    useDisableBodyScroll,
    useScrollingUp,
} from '@soluto-private/mx-asurion-ui-react-v3';

import { GlobalHeaderDesktop } from './GlobalHeaderDesktop';
import { GlobalHeaderInnerProps } from './types';
import { GlobalHeaderMobile } from './GlobalHeaderMobile';

export type GlobalHeaderProps = GlobalHeaderInnerProps & {
    id?: string;
    depthOffset?: number;
    subNavConfig?: SubNavProps;
    contentfulConfig?: ContentfulConfig;
};

export const GlobalHeader: FC<GlobalHeaderProps> = forwardRef<
    HTMLDivElement,
    GlobalHeaderProps
>(function GlobalHeader(
    {
        id,
        depthOffset = 0,
        subNavConfig,
        contentfulConfig,
        isLoggedIn = false,
        ...props
    },
    fRef
) {
    const [isNavMenuActive, setNavMenuActive] = useState(false);
    const [isMyAccountMenuActive, _setMyAccountMenuActive] = useState(false);
    const setMyAccountMenuActive = useCallback(
        (action: React.SetStateAction<boolean>) =>
            _setMyAccountMenuActive(
                (prev) =>
                    isLoggedIn &&
                    (typeof action === 'function' ? action(prev) : action)
            ),
        [isLoggedIn]
    );
    const closeMenus = useCallback(() => {
        setNavMenuActive(false);
        setMyAccountMenuActive(false);
    }, [setMyAccountMenuActive]);

    const screenSize = useScreenSize();
    useEffect(closeMenus, [closeMenus, screenSize]);
    useEffect(() => {
        if (!isLoggedIn) {
            setMyAccountMenuActive(false);
        }
    }, [isLoggedIn, setMyAccountMenuActive]);

    const isScrollingUp = useScrollingUp(true);
    useDisableBodyScroll(
        isNavMenuActive ||
            (screenSize === ScreenSize.Small && isMyAccountMenuActive)
    );
    useEffect(() => {
        if (!isScrollingUp) {
            closeMenus();
        }
    }, [isScrollingUp]);

    const showHeader =
        typeof window === 'undefined' || isScrollingUp || isNavMenuActive;

    const GlobalHeaderContent =
        screenSize === ScreenSize.Small
            ? GlobalHeaderMobile
            : GlobalHeaderDesktop;

    return (
        <ContentfulProvider config={contentfulConfig}>
            <S.Header
                ref={fRef}
                id={id}
                showHeader={showHeader}
                depthOffset={depthOffset}
                isSubNav={!!subNavConfig}
            >
                <S.Container>
                    <GlobalHeaderContent
                        isLoggedIn={isLoggedIn}
                        isNavMenuActive={isNavMenuActive}
                        isMyAccountMenuActive={isMyAccountMenuActive}
                        setMyAccountMenuActive={setMyAccountMenuActive}
                        setNavMenuActive={setNavMenuActive}
                        closeMenus={closeMenus}
                        {...props}
                    />
                </S.Container>
            </S.Header>
            <S.Overlay
                isActive={
                    screenSize === ScreenSize.Small &&
                    (isNavMenuActive || isMyAccountMenuActive)
                }
                onClick={closeMenus}
                depthOffset={depthOffset}
            />
            {screenSize === ScreenSize.Large && !!subNavConfig && (
                <SubNav {...subNavConfig} />
            )}
        </ContentfulProvider>
    );
});

import React, { FunctionComponent } from 'react';

const TaskListHeartIcon: FunctionComponent = () => (
    <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23 31.0146L16.1814 23.9013C14.9535 22.6738 14.649 20.7982 15.4254 19.2453V19.2453C16.0055 18.0855 17.1077 17.2753 18.3878 17.0676C19.6679 16.86 20.9697 17.2803 21.8867 18.1973L23 19.3106L24.1134 18.1973C25.0303 17.2803 26.3322 16.86 27.6122 17.0676C28.8923 17.2753 29.9946 18.0855 30.5747 19.2453V19.2453C31.3511 20.7982 31.0465 22.6738 29.8187 23.9013L23 31.0146Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7 14.0146H14"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7 19.0146H10"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7 24.0146H10"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13 31.0146H3C1.89543 31.0146 1 30.1192 1 29.0146V8.01465C1 6.91008 1.89543 6.01465 3 6.01465H8C8 3.25322 10.2386 1.01465 13 1.01465C15.7614 1.01465 18 3.25322 18 6.01465H23C24.1046 6.01465 25 6.91008 25 8.01465V11.0146"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13 5.01465C13.2761 5.01465 13.5 5.23851 13.5 5.51465C13.5 5.79079 13.2761 6.01465 13 6.01465C12.7239 6.01465 12.5 5.79079 12.5 5.51465C12.5 5.23851 12.7239 5.01465 13 5.01465"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default TaskListHeartIcon;

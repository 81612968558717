import React, { FC } from 'react';

import { Container } from './commonStyles';
import { ContainerItemProps } from './types';

export const NavCardContainer: FC<ContainerItemProps> = ({
    href,
    index,
    innerRef,
    ...restProps
}) => {
    return (
        <Container
            tabIndex={0}
            role="menuitem"
            href={href}
            as={href ? 'a' : 'button'}
            index={index}
            ref={innerRef}
            {...restProps}
        />
    );
};

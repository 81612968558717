import { KeyboardEvent, useCallback } from 'react';

import { Key } from '../utils';

export type Dimensions = {
    items: number;
    columns: number;
};

const getNextFocus = {
    [Key.ArrowDown]: ({ items, columns }: Dimensions, current: number) =>
        Math.min(current + columns, items - 1),
    [Key.ArrowLeft]: (_: Dimensions, current: number) =>
        Math.max(current - 1, 0),
    [Key.ArrowUp]: ({ columns }: Dimensions, current: number) =>
        Math.max(current - columns, 0),
    [Key.ArrowRight]: ({ items }: Dimensions, current: number) =>
        Math.min(current + 1, items - 1),
};

const isArrowKey = (key: string): key is keyof typeof getNextFocus =>
    typeof getNextFocus[key as keyof typeof getNextFocus] === 'function';

export type UseArrowControlsProps = {
    dimensions: Dimensions;
    focusParent: () => void;
    focusItem: (itemIndex: number) => void;
};

export const useArrowControls = <T extends HTMLAnchorElement>({
    dimensions: { items, columns },
    focusParent,
    focusItem,
}: UseArrowControlsProps) => {
    const onKeyDown = useCallback(
        (itemIndex: number) => (e: KeyboardEvent<T>) => {
            if (e.shiftKey || !isArrowKey(e.key)) {
                return;
            }

            if (itemIndex === 0 && e.key === Key.ArrowUp) {
                e.preventDefault();
                focusParent();
                return;
            }

            const nextFocus = getNextFocus[e.key](
                { items, columns },
                itemIndex
            );
            focusItem(nextFocus);
            e.preventDefault();
        },
        [items, columns, focusItem]
    );

    return { onKeyDown };
};

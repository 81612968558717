import React, { FunctionComponent, useMemo } from 'react';
import { ServiceCategoryName, ServiceItemType } from './types';

import ServiceCategoryItem from './ServiceCategoryItem';
import { categoryTypeToCategoryMap } from './mappings';

type Props = {
    services: ServiceItemType[];
};

const ServicesRenderer: FunctionComponent<Props> = ({ services }) => {
    const uniqueCategories = useMemo(() => {
        return Object.keys(categoryTypeToCategoryMap).filter((c) =>
            services.some(({ category }) => category === c)
        );
    }, [services]);

    return (
        <>
            {uniqueCategories.map((category) => {
                const categoryItem =
                    categoryTypeToCategoryMap[category as ServiceCategoryName];
                const categoryServices = services.filter(
                    (service) => service.category === category
                );

                return (
                    <ServiceCategoryItem
                        key={category}
                        {...categoryItem}
                        services={categoryServices}
                    />
                );
            })}
        </>
    );
};

export default ServicesRenderer;

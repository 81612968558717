import React, { FunctionComponent } from 'react';

import ExternalViewHeader from './ExternalViewHeader';
import { HeaderViewType } from './types';
import MainViewHeader from './MainViewHeader';
import ServiceViewHeader from './ServiceViewHeader';
import useHeaderState from '../../hooks/useHeaderState';

const viewTypeToHeaderMapping: Record<HeaderViewType, FunctionComponent> = {
    'main-view': MainViewHeader,
    'service-view': ServiceViewHeader,
    'external-view': ExternalViewHeader,
};

const Header: FunctionComponent = () => {
    const { view } = useHeaderState();

    if (!view) {
        return null;
    }

    const HeaderComponent = viewTypeToHeaderMapping[view];
    return <HeaderComponent />;
};

export default Header;

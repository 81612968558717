import { MenuItemName } from '../types';
import { serviceToMenuItemsMap } from '../mappings';
import { useMemo } from 'react';
import { useServices } from '@soluto-private/mx-context-react';

const useMenuItems = (): MenuItemName[] => {
    const services = useServices();

    const menuItems = useMemo(
        () =>
            (services || [])
                .map((service) => serviceToMenuItemsMap[service])
                .filter((service): service is MenuItemName[] => !!service)
                .reduce((prev, curr) => [...prev, ...curr], []),
        [services]
    );

    return menuItems;
};

export default useMenuItems;

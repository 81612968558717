import React, { FunctionComponent } from 'react';

const FileEdit: FunctionComponent = () => (
    <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M31.5066 21.16L21.3333 31.3333L16.3333 32.3333L17.3333 27.3333L27.5066 17.16C28.0354 16.6304 28.753 16.3329 29.5013 16.3329C30.2496 16.3329 30.9672 16.6304 31.496 17.16L31.5066 17.1707C32.0362 17.6994 32.3338 18.417 32.3338 19.1653C32.3338 19.9137 32.0362 20.6313 31.5066 21.16Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.3333 32.3333H2.99996C2.26358 32.3333 1.66663 31.7364 1.66663 31V2.99999C1.66663 2.26361 2.26358 1.66666 2.99996 1.66666H20.724C21.0775 1.66674 21.4166 1.80726 21.6666 2.05733L26.6093 6.99999C26.8594 7.24998 26.9999 7.58907 27 7.94266V12.3333"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default FileEdit;

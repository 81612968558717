import React, { FunctionComponent } from 'react';

const RepairIcon: FunctionComponent = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19 9C19 6.044 17.125 3.394 14.334 2.407L13 1.934V8C13 8.553 12.551 9 12 9C11.449 9 11 8.553 11 8V1.934L9.666 2.407C6.875 3.394 5 6.044 5 9C5 11.786 6.639 14.188 9 15.315V22H11V16H13V22H15V15.315C17.361 14.188 19 11.785 19 9ZM12 14C9.243 14 7 11.757 7 9C7 7.405 7.766 5.935 9 5.007V8C9 9.654 10.346 11 12 11C13.654 11 15 9.654 15 8V5.006C16.234 5.934 17 7.404 17 9C17 11.756 14.757 14 12 14Z"
            fill="black"
        />
    </svg>
);

export default RepairIcon;

import React, { FunctionComponent } from 'react';

const OptimizeIcon: FunctionComponent = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10 17H14V19H10V17Z" fill="black" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22 14.838L23.632 19.734C23.818 20.293 23.742 20.865 23.424 21.307C23.106 21.747 22.587 22 22 22H2.00001C1.41301 22 0.894014 21.747 0.576014 21.306C0.258014 20.865 0.182014 20.293 0.368014 19.735L2.00001 14.838V3C2.00001 2.447 2.44801 2 3.00001 2H21C21.552 2 22 2.447 22 3V14.838ZM20 14V4H4.00001V14H20ZM3.72101 16L2.38801 20H21.613L20.28 16H3.72101Z"
            fill="black"
        />
    </svg>
);

export default OptimizeIcon;

import { useContentfulItem } from './useContentfulItem';
import { useMemo } from 'react';

export type GlobalHeaderMenuContent = {
    id: string;
    title: string;
    description?: string;
    iconSource?: string;
    linkText?: string;
    linkUrl?: string;
    columnsMobile: number;
    columnsDesktop: number;
};

export const useGlobalHeaderMenuContent = (
    menu: string
): GlobalHeaderMenuContent => {
    const query = useMemo(
        () => ({
            type: 'globalHeaderMenu',
            tags: [menu],
            defaultProps: {
                id: '',
                title: '',
                description: '',
                iconSource: '',
                linkText: '',
                linkUrl: '',
                columnsMobile: 1,
                columnsDesktop: 3,
            },
        }),
        [menu]
    );

    const { result } = useContentfulItem<GlobalHeaderMenuContent>(query);

    return {
        id: result?.id ?? '',
        title: result?.title ?? '',
        description: result?.description,
        iconSource: result?.iconSource,
        linkText: result?.linkText,
        linkUrl: result?.linkUrl,
        columnsMobile: result?.columnsMobile ?? 1,
        columnsDesktop: result?.columnsDesktop ?? 3,
    };
};

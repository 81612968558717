import {
    MenuContentBaseProps,
    MenuItemConfig,
    isContentfulMenuItem,
    isCustomMenuItem,
} from '../../../../types';
import React, { FC } from 'react';

import { ContentfulMenuContent } from '../../../../content';

type MenuContentProps = MenuContentBaseProps & {
    item: MenuItemConfig;
};

export const MenuContent: FC<MenuContentProps> = ({ item, ...props }) => {
    if (isContentfulMenuItem(item)) {
        return <ContentfulMenuContent menuName={item.menuName} {...props} />;
    } else if (isCustomMenuItem(item)) {
        const Content = item.content;
        return <Content {...props} />;
    }
    return null;
};
